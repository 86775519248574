<div class="w-100 h-100 p-4 pb-0 contenedor">
  <div class="custom_row align-items-center bg-white p-2">
    <div class="col-6 m-0">
      <div class="document-container d-flex align-items-center">
        <a
          href="https://manuales-compass.s3.amazonaws.com/Portal+CG+Dynamic+-+Guia+de+Usuario.pdf"
          class="link-manuales">
          <img src="../../../assets/icons/i-file.svg" alt="Archivo" />
          <span class="spanSubTitle"
            >Guía de Usuario Reportes de Inversión</span
          >
        </a>
      </div>
    </div>
  </div>
</div>
