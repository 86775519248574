import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable()
export class ModalService {
  /**
   * @property {BehaviorSubject} A new instance from Behavior Subject
   * @private
   */
  private messageSource = new BehaviorSubject<any>({
    showModal: false,
    body: '',
    url: ''
  });
  /**
   * @property {Observable} Observable object
   * @public
   */
  currentMessage = this.messageSource.asObservable();
  constructor() {}
  /**
   * @description Insert a new status in the chain
   * @param showModal {boolean} Show or Hide Modal
   */
  show(showModal: boolean, body: string, url:string) {
    this.messageSource.next({
      showModal: showModal,
      body: body,
      url:url
    });
  }
}
