import { Component, OnInit } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BaseService } from '../base/base.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends BaseService implements OnInit {
  /*
   *Create user data obserbable
   */
  public sendUser = new Subject();
  userObserbable = this.sendUser.asObservable();
  /*
   *Create permissions user obserbable
   */

  private currentPermitionsSubject: BehaviorSubject<string[]> =
    new BehaviorSubject(['']);
  public readonly currentPermitions: Observable<string[]> =
    this.currentPermitionsSubject.asObservable();
    

  public elements = ['Home'];
  public timechange;
  public infoData:any = [];

  async ngOnInit(){

    let email = this.session.getEmail();
    this.loaderTransparent.show(true);

    this.getAll('/contract?email=' + email).subscribe(response => {
      if (response.message.includes('EHOSTUNREACH')) {
        this.showNotification("Lo sentimos, experimentamos un error temporal.");
        this.loaderTransparent.show(false);
      }

      if (response.success !== false) {
        this.sendUser.next(response['data']);
        console.log(response['data'].country);
        

        const bussines = response.data.companies.map(item => ({
          contracts: item.contracts,
          name: item.name,
          id: item.id + 1,
        }));
        localStorage.setItem('bussiness', bussines[0].id);
        localStorage.setItem('contract', bussines[0].contract);

        let arrayfirstMenu = [...response.data.permission];
        arrayfirstMenu = arrayfirstMenu.slice(0,6);

        for (const iterator of arrayfirstMenu) {
          if (iterator.allow === true) {
            this.elements.push(iterator.name);
          }
        }
        this.elements.push('Cerrar sesión');
        this.currentPermitionsSubject.next(this.elements);
        this.loaderTransparent.show(false);
      }

     
    });
    this.ObtenerUbicacion();
    this.activateClosesesion();
  }

  getElements() {
    return this.elements;
  }
  private showNotification(message: string): void {
    this.notification.show('block', '', message, '', 'error', '');
  }
}