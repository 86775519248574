<div class="w-100 h-100 d-flex flex-column overflow-hidden">
  <app-navbar></app-navbar>
  <div class="h-100 w-100 flex-row d-flex margin-container">
    <div class="col-md-2 d-sm-block">
      <app-sidebar></app-sidebar>
    </div>
    <div class="col-xs-12 col-md-10 h-100 overflow-auto height_phone">
      <div class="w-100 flex-column-reverse">
        <router-outlet></router-outlet>
        <div class="d-flex mt-5 mb-5 position-bottom">
          <div class="container">
            <div class="row">
              <div class="col-12 d-flex flex-row-reverse">
                <div class="col-7 text-end">
                  <a class="info-btns me-2" href="https://manuales-compass.s3.amazonaws.com/guia-servicios-de-inversion.pdf" target="_blank">Guia de servicios de inversón</a>
                  <a class="info-btns me-2" href="https://manuales-compass.s3.amazonaws.com/disclaimer.pdf" target="_blank">Disclaimer</a>
                  <a class="info-btns me-2" href="https://manuales-compass.s3.amazonaws.com/terminos-y-condiciones.pdf" target="_blank">Terminos y condiciones</a>
                  <a class="info-btns" href="https://manuales-compass.s3.amazonaws.com/aviso-de-privacidad.pdf" target="_blank">Aviso de privacidad</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>