import { Component, OnInit } from '@angular/core';
import { ChartConfiguration } from 'chart.js';
import { BaseService } from 'src/app/base/base.service';
import {
  portfolioInterface,
  valuePortfolioInterface,
} from 'src/app/common/dtos/contractsDTOs';
import { ExportService } from 'src/app/util/exportFiles/export.service';

@Component({
  selector: 'app-valor-portafolio',
  templateUrl: './valor-portafolio.component.html',
  styleUrls: ['./valor-portafolio.component.scss'],
})
export class ValorPortafolioComponent extends BaseService implements OnInit {
  /**
   * @property {Array} List for rows table
   * @public
   */
  public data: valuePortfolioInterface[] = [];
  /**
   * @property {Object} Selected bussines data
   * @private
   */
  private bussines: any = {};
  /**
   * @property {Array} List for generals rows table
   * @public
   */
  public generals: portfolioInterface[] = [];
  /**
   * @property {Array} List for charts rows table
   * @public
   */
  public charts: [] = [];
  /**
   * @property {Boolean} Show Hide chart while fill data
   * @private
   */
  public showChart: boolean = false;

  /**
   * @property {string} Id Contract for query params
   * @public
   */
  public idContract: string = '';
  /**
   * @property {string} Date start for query params
   * @private
   */
  private startDate: string = '';
  /**
   * @property {string} Date end for query params
   * @private
   */
  private endDate: string = '';

  isDownloading: boolean = false;

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Valor del portafolio',
        backgroundColor: '#002FA7',
        borderColor: '#002FA7',
        pointBackgroundColor: '#002FA7',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: false,
        pointStyle: 'circle',
      },
    ],
    labels: [],
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    plugins: {
      legend: { display: true, onClick: e => {} },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            return this.formatLabelChartTooltip(tooltipItem);
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        ticks: {
          callback: label => {
            return this.formatLabelChartYAxies(label);
          },
        },
      },
    },
  };

  ngOnInit(): void {
    this.loaderTransparent.show(true);
    this.dataBreadcum = [{
      breadcum: ['Home', 'Portal de clientes','Resumen del portafolio', 'Valor del portafolio'],
      title: 'Valor del portafolio',
      subTitle : '',
      icon:false
     }]
  }

  public getContactData(contactData) {
    this.showChart = false;
    this.loaderTransparent.show(true);
    this.bussines = contactData.bussines;
    this.idContract = contactData.contract;
    this.startDate = contactData.date;
    this.endDate = contactData.dateEnd;
    this.getAll(
      '/contract/' +
        this.idContract +
        '/porfolio?fechaInicial=' +
        this.startDate +
        '&fechaFinal=' +
        this.endDate +
        '&contrato=' +
        this.idContract
    ).subscribe(response => {
      this.generals = response['data'] ? response.data.generals : [];
      let data = response['data'] ? response.data.value.reverse() : [];

      let formatData = this.formatDateAndTime(data);

      this.data = formatData.sort(this.sortByDates);

      this.data = this.data.reverse();

      if (response['data']['value'].length > 0) {
        this.lineChartData.datasets[0].data = this.data.map(item =>
          Number(item['total'])
        );
        this.lineChartData.labels = this.data.map(item => item['date']);

        this.showChart = true;
      }

      setTimeout(() => {
        this.loaderTransparent.show(false);
      }, 1200);
    });
  }

  formatDateAndTime(data) {
    return data.map(item => {
      let dateFormat = new Date(item.date.replace(/-/g, '/'));

      let day = dateFormat.getDate().toString();
      let month = (dateFormat.getMonth() + 1).toString();
      let year = dateFormat.getFullYear().toString().substr(-2);

      let date = day + '/' + month + '/' + year;

      return {
        date,
        cashBalance: item.cashBalance,
        value: item.value,
        total: item.total,
      };
    });
  }

  async printPDF() {
    let tableGenerals = document.getElementById('scroll')!;
    tableGenerals.classList.remove('scroll-table');

    const empresa = this.bussines.name;
    const fechaInicial = this.formatDate(this.startDate);
    const fechaFinal = this.formatDate(this.endDate);
    this.isDownloading = true;
    const id = this.idContract;

    let card = document.getElementById('card')!;
    card.classList.remove('card-table-height');
    card.classList.remove('scroll-table');

    const chartImage = await ExportService.convertHTMLtoImage('porfolioChart');
    const imgH = await ExportService.getImageFromLocal(
      '/assets/images/logo.png'
    );
    var graphTableDiv = await ExportService.convertHTMLtoImage('graphTableDiv');

    const date = this.formatDateTime(new Date());

    const pdfTHeaders = [
      { text: 'Fecha', bold: true, fontSize: 8, alignment: 'center' },
      //{text: 'Hora', bold:true, fontSize: 8, alignment: 'center'},
      { text: 'Valor', bold: true, fontSize: 8, alignment: 'center' },
      {
        text: 'Saldo en Efectivo',
        bold: true,
        fontSize: 8,
        alignment: 'center',
      },
      { text: 'Total', bold: true, fontSize: 8, alignment: 'center' },
    ];

    let rows: [any[]] = [pdfTHeaders];

    for (let row of this.data) {
      let r = [
        { text: row.date.toString(), fontSize: 8, alignment: 'center' },
        {
          text: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(parseFloat(row.value.toString())),
          fontSize: 8,
          alignment: 'right',
        },
        {
          text: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(parseFloat(row.cashBalance.toString())),
          fontSize: 8,
          alignment: 'right',
        },
        {
          text: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(parseFloat(row.total.toString())),
          fontSize: 8,
          alignment: 'right',
        },
      ];
      rows.push(r);
    }

    var docDefinition = {
      pageOrientation: 'landscape',
      pageMargins: [20, 150, 10, 30],
      header: function (currentPage, pageCount) {
        return [
          { image: imgH, width: 150, margin: 20 },
          {
            text: 'Contrato ' + id + ' | "' + empresa + '"',
            margin: [20, 0, 0, 10],
            bold: true,
          },
          {
            text: 'Desde ' + fechaInicial + ' hasta ' + fechaFinal,
            margin: [20, 0, 0, 10],
          },
        ];
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            columns: [
              {
                text: 'Fecha y hora de descarga: ' + date,
                alignment: 'left',
                width: 'auto',
                margin: [20, 0, 0, 0],
              },
              {
                text: 'Página ' + currentPage.toString() + ' de ' + pageCount,
                alignment: 'right',
                margin: [0, 0, 20, 0],
              },
            ],
          },
        ];
      },
      content: [
        {
          text: 'Valor del Portafolio',
          margin: [0, 0, 0, 10],
          fontSize: 18,
          color: '#002FA7',
        },
        { image: graphTableDiv, width: 500, margin: [20, 0, 0, 0] },
        { image: chartImage, width: 300, margin: [0, 20, 0, 0] },
        {
          layout: 'noBorders',
          pageBreak: 'before',
          table: {
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto'],
            body: rows,
          },
        },
      ],
      styles: {
        header: {
          background: 'red',
          lineHeight: 3,
        },
      },
    };

    let rowsExcelT1: [any[]] = [['Valor', 'Saldo en Efectivo', 'Total']];

    for (let row of this.generals) {
      let r = [
        { t: 'n', v: row.value.toString() },
        { t: 'n', v: row.cashBalance.toString() },
        { t: 'n', v: row.total.toString() },
      ];

      rowsExcelT1.push(r);
    }

    let rowsExcelT2: [any[]] = [
      ['Fecha', 'Valor', 'Saldo en Efectivo', 'Total'],
    ];

    for (let row of this.data) {
      let r = [
        insert(row.date.toString(), -2, '20'),
        { t: 'n', v: row.value.toString() },
        { t: 'n', v: row.cashBalance.toString() },
        { t: 'n', v: row.total.toString() },
      ];

      rowsExcelT2.push(r);
    }

    const tables = [
      { rows: rowsExcelT1, name: 'Portafolio Generales' },
      { rows: rowsExcelT2, name: 'Portafolio Detalles' },
    ];
    await ExportService.exportToExcel(tables, 'portafolio.xlsx');
    ExportService.exportToPDF(docDefinition, false);

    this.isDownloading = false;
    tableGenerals.classList.add('scroll-table');
    card.classList.add('card-table-height');
    card.classList.add('scroll-table');
  }

  private formatDateTime(date) {
    let dateFormat = new Date(date);

    let day = ('0' + dateFormat.getDate().toString()).slice(-2);
    let month = ('0' + (dateFormat.getMonth() + 1).toString()).slice(-2);
    let year = dateFormat.getFullYear().toString().toString();
    let hour = ('0' + dateFormat.getHours().toString()).slice(-2);
    let minutes = ('0' + dateFormat.getMinutes().toString()).slice(-2);
    let seconds = ('0' + dateFormat.getSeconds().toString()).slice(-2);

    return (
      day +
      '/' +
      month +
      '/' +
      year +
      ' ' +
      hour +
      ':' +
      minutes +
      ':' +
      seconds
    );
  }

  private formatDate(date) {
    let dateFormat = new Date(date);

    let day = ('0' + dateFormat.getUTCDate().toString()).slice(-2);
    let mesTmp = dateFormat.getUTCMonth();
    let month = ('0' + (mesTmp + 1).toString()).slice(-2);
    let year = dateFormat.getFullYear().toString().toString();

    return day + '/' + month + '/' + year;
  }

  private formatLabelChartTooltip(value) {
    let formatNumber = Number(value.parsed.y);
    return (
      '$' +
      formatNumber.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }

  private formatLabelChartYAxies(value) {
    let formatNumber = Number(value);
    return '$' + formatNumber.toLocaleString('en-US');
  }

  private sortByDates(a, b) {
    return (
      new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime()
    );
  }
}

const insert = function (strOrigen, index, str) {
  var ind = index < 0 ? strOrigen.length + index : index;
  return strOrigen.substring(0, ind) + str + strOrigen.substr(ind);
};
