<div class="fadeIn">
  <div class="logo_phone">
    <img
      class="logo"
      src="/assets/images/logo-blanco.png"
      alt="logo-compasss-blanco"
      height="90px"
      width="250px" />
  </div>
  <div class="public-pages d-flex justify-content-center align-items-center">
    <div class="d-flex w-100 justify-content-center">
      <div
        class="fadeIn card card-login col-xxl-3 col-xl-4 col-lg-5 col-md-8 col-10 mb-5">
        <ng-container *ngIf="showLogin">
          <h1>Registro de cuenta</h1>
          <span class="spanAlternativeSubTitle weight-300 mb-3"
            >Por favor rellena los siguientes datos</span
          >
          <form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
            <span class="spanAlternativeSubTitle secLabel weight-300" for="mail"
              >Email de cliente</span
            >
            <input
              type="email"
              formControlName="user"
              [attr.autocomplete]="'off'"
              aria-autocomplete="none"
              name="uusuario"
              class="form-control mb-3" />
            <span class="spanAlternativeSubTitle secLabel weight-300" for="new"
              >Contraseña</span
            >
            <input
              type="password"
              id="password"
              [attr.autocomplete]="'off'"
              aria-autocomplete="none"
              formControlName="password"
              class="form-control mb-2" />
            <span
              class="material-symbols-outlined buttton-eye"
              (click)="showPass()">
              {{ eyeIcon }}
            </span>
            <span class="spanAlternativeSubTitle secLabel weight-300" for="new"
              >Confirmar contraseña</span
            >
            <input
              type="password"
              id="passwordConfirm"
              onCopy="return false"
              onDrag="return false"
              onDrop="return false"
              onPaste="return false"
              [attr.autocomplete]="'off'"
              formControlName="confirmPassword"
              class="form-control mb-2" />
            <span
              class="material-symbols-outlined buttton-eye"
              (click)="showPassConfirm()">
              {{ eyeIconConfirm }}
            </span>
            <div class="password-requirements mb-4">
              <span> Contraseña debe contener al menos: </span>
              <ul>
                <li>8 caracteres</li>
                <li>1 número</li>
                <li>1 mayúscula</li>
                <li>1 minúscula</li>
                <li>1 caracter especial !@#$%^&*-.</li>
              </ul>
            </div>
            <button
              type="submit"
              class="btn btn-primary w-100"
              [disabled]="myForm.invalid">
              Registrar
            </button>
            <hr />
            <div class="d-flex justify-content-center mt-3">
              <a class="" [ngClass]="{'text-white btn btn-primary w-100': userExist === true}" routerLink="/login" routerLinkActive="active">
                Iniciar sesión</a
              >
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="!showLogin">
          <h1>Ingresar código</h1>
          <form [formGroup]="codeForm" (ngSubmit)="sendCode(codeForm)">
            <label for="mail">Código enviado al correo</label>
            <input
              type="text"
              formControlName="code"
              name="codigoCorreo"
              class="form-control mb-3" />

            <div class="d-flex buttons_code_public">
              <button
                type="button"
                class="btn btn-secondary col-5 mr-3 w-45"
                (click)="reSendCode()">
                Reenviar código
              </button>
              <button
                type="submit"
                class="btn btn-primary col-5 ml-3 w-45"
                [disabled]="codeForm.invalid">
                Continuar
              </button>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
</div>
