import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { BaseService } from 'src/app/base/base.service';

import { valuePortfolioInterface } from 'src/app/common/dtos/contractsDTOs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-transacciones',
  templateUrl: './transacciones.component.html',
  styleUrls: ['./transacciones.component.scss'],
})
export class TransaccionesComponent extends BaseService implements OnInit {
  disabledbtn: boolean = true;
  companies: any = [];
  total_pages: number = 0;
  data_to_show: Array<any> = []; //data para posiciones
  data_to_combo: Array<any> = []; //data de los fondos para el combo
  data_bancos: any = []; //data de los bancos
  forma_pago: any = []; //data formas de pago
  datos_series: Array<any> = []; //data de la informacion del fondo.
  limit: number = 8;
  item_selected: any = {};
  last_selected: string;
  public elements = ['Home'];
  public loaders: boolean = false;
  public loadersmov: boolean = false;
  public idContract: any;
  validacion = false;

  title: string;
  empresa: string;
  contrato: string;
  tipoOpe: string = 'nodisponible';
  public startDate: string = '';
  private endDate: string = '';
  public porfolio: valuePortfolioInterface[] = [];
  //publicar canttidades solas
  public cashBalance: number = 0;
  public posicions: any = { amount: 0 };
  public movements: any[] = [];
  public dataTransaccion: any = {};
  public AllCash: any = [];

  operacion: string = '';

  bussiness: any = [];
  manager: any = {};
  user: string;
  processstep: string;
  id: string = '';
  showMessageToDowload = false;
  accionesPropiasId: any;

  /* Paginador */
  @Output() current_page = new EventEmitter<number>();
  page: number = 1;
  pages: Array<any> = [];
  @ViewChild('dateInput') dateInput!: ElementRef;
  valorPorDefecto = ['Seleccionar fondo'];
  public subscription = new Subscription();

  async ngOnInit() {
    this.loaderTransparent.show(true);
    this.dataBreadcum = [
      {
        breadcum: ['Home', 'Portal de clientes', 'Transacciones'],
        title: 'Operaciones',
        subTitle: 'Compra y venta de fondos',
        icon: true,
        path: 'https://manuales-compass.s3.amazonaws.com/Caracteristicas-Fondos.pdf',
      },
    ];

    this.loadersmov = true;
    this.dataTransaccion.itemselected = true;
    this.processstep = 'visualizar';
    this.dataTransaccion.fondo = '';
    this.dataTransaccion.acciones_id = '';
    this.dataTransaccion.operacion = 'comprar';
    this.dataTransaccion.tipooperacion = 'monto';
    this.dataTransaccion.operacionventa = true;
    this.dataTransaccion.mensajeValid = false;
    localStorage.setItem('itemSelected', 'Transaccional');
    this.startDate = this.getFechaHoy();
    this.endDate = this.startDate;
    setTimeout(() => {
      let monto = document.getElementById('titulos') as HTMLInputElement;
      monto.checked;
    }, 1000);

    let email = this.session.getEmail();
    this.getAll('/contract?email=' + email).subscribe(response => {
      this.manager = response['data'] ? response.data.accManager : { name: '' };

      this.bussiness = response.data.companies.map(item => ({
        contracts: item.contracts,
        name: item.name,
        id: item.id + this.getRandomInt().toString(),
      }));

      let arrayTransaccion: any = [...response.data.permission];
      arrayTransaccion = arrayTransaccion.slice(6, 8);

      if (
        arrayTransaccion[0].allow === false &&
        arrayTransaccion[1].allow === false
      ) {
        this.router.navigate(['/portal-clientes/contratos']);
      }
      if (
        arrayTransaccion[0].allow === false &&
        arrayTransaccion[1].allow === true
      ) {
        this.router.navigate(['/portal-clientes/contratos']);
      }
      if (
        arrayTransaccion[0].allow === true &&
        arrayTransaccion[1].allow === false
      ) {
        this.router.navigate(['/portal-clientes/contratos']);
      }
      this.user = response['data'] ? response.data.user : '';
      localStorage.setItem('userName', response.data.user);
      this.loader.show(false);
    });

    this.activateRouter.paramMap.subscribe(async (params: any) => {
      if (params.params.id != undefined) {
        this.id = params.params.id;
        this.dataTransaccion.fondo = this.id;
        const datacv: any = await this.getDataCVP(
          'CPA',
          '1',
          localStorage.getItem('contract')
        );
        const item = datacv.find(
          ele =>
            ele.fondo === params.params.id && ele.serie === params.params.serie
        );
        this.dataTransaccion.fondo = item.fondo;
        this.dataTransaccion.serie = item.serie;
        setTimeout(() => {
          this.dataTransaccion.acciones_id = item.acciones_propias_id;
        }, 2000);
      }
    });
  }
  cont = 0; ///TODO borrar cuando los de compass remieden sus id en los contratos
  getRandomInt() {
    this.cont = this.cont + 1;
    return this.cont;
  }

  async actualizar() {
    this.loaderTransparent.show(true);
    this.data_to_show = [];
    this.paginador(this.data_to_show);
    await this.getPositions();
    await this.getMovimientos('1');
    await this.getDataCompraVenta(this.dataTransaccion.operacion, '1');
    this.AllCash = await this.getSaldos(this.idContract);
    this.cashBalance = this.AllCash.efectivo;
    this.loaderTransparent.show(false);
  }

  getElements() {
    return this.elements;
  }

  receiveDataFromChild(data: any) {
    if (data.destino === 'confirmar') {
      this.processstep = data.destino;
      this.operacion = 'transferencia';
      this.dataTransaccion = data.data;
    }
    if (data.destino === 'cancelar') {
      this.dataTransaccion.fondo = '';
      this.dataTransaccion.cantidad = '';
      this.dataTransaccion.acciones_id = '';
      this.operacion = 'comrpar';
      this.processstep = 'visualizar';
    }
  }

  async ReceiveDataFromConfirmar(data: any) {
    console.log(data);
    if (data.destino === 'cancelar') {
      this.dataTransaccion.fondo = '';
      this.dataTransaccion.cantidad = '';
      this.dataTransaccion.acciones_id = '';
      this.operacion = 'comprar';
      this.processstep = 'visualizar';
    }
    if (data.destino === 'visualizar') {
      this.operacion = data.opcion;
      this.processstep = data.destino;
    }
    if (data.destino == 'recursos') {
      this.dataTransaccion = data;
      this.operacion = data.opcion;
      this.processstep = data.destino;
    }
    if (data.destino === 'terminar') {
      this.dataTransaccion.fondo = '';
      this.dataTransaccion.cantidad = '';
      this.dataTransaccion.acciones_id = '';
      this.operacion = 'comprar';
      this.processstep = 'visualizar';
      this.data_to_show = [];
      this.paginador(this.data_to_show);
      await this.getPositions();
      await this.getDataCompraVenta('CPA', '1');
      this.AllCash = await this.getSaldos(this.idContract);
      this.cashBalance = this.AllCash.efectivo;
    }
    await this.getMovimientos('1');
  }

  async getContactData(contactData) {
    this.loaderTransparent.show(true);
    this.idContract = contactData.contract;
    //this.startDate = '2024-01-03';
    await this.getPositions();
    //this.startDate = '2024-02-18';
    //this.endDate = '2024-02-18';

    await this.getDataCompraVenta('CPA', '1');
    await this.getMovimientos('1');
    this.AllCash = await this.getSaldos(this.idContract);
    this.cashBalance = this.AllCash.efectivo;
    setTimeout(() => {
      this.loaderTransparent.show(false);
    }, 4000);
  }

  async acction(tipoO: number) {
    //accion que se ejecuta al seleccionar el fondo desde el combo.
    this.loaderTransparent.show(true);
    this.dataTransaccion.operacion = tipoO === 1 ? 'comprar' : 'vender';
    this.dataTransaccion.cantidad = '';
    if (tipoO === 1) {
      //1 para comprar, da valor op: 1, para comprar, y operacion CPA, la opcion 2: la valor op 2 para vender, y operacion VTA.
      await this.getDataCompraVenta('CPA', '1');
      this.loaderTransparent.show(false);
    } else {
      await this.getDataCompraVenta('VTA', '2');
      this.loaderTransparent.show(false);
    }
    await this.getDatoSerie(this.accionesPropiasId, 'action');
  }

  selectElement(item: any) {
    if (this.last_selected === undefined) {
      item.isSelected = true;
      this.last_selected = item.fund;
    } else {
      this.data_to_show.forEach(element => {
        if (element.fund === this.last_selected) {
          element.isSelected = false;
        }
      });
      this.last_selected = item.fund;
      item.isSelected = true;
    }
    this.dataTransaccion.fondo = item.fund;
    this.dataTransaccion.serie = item.serie;
    this.getDatoSerie('', 'element');
  }

  public paginador(list = this.data_to_show) {
    this.pages = [];
    const totalPages = Math.ceil(list.length / this.limit);
    this.total_pages = totalPages;
    for (let p = 0; p < totalPages; p++) {
      this.pages.push(p);
    }
  }

  arrowPrevious() {
    if (!(this.page - 1 < 1)) {
      this.page = this.page - 1;
      this.current_page.emit(this.page);
    }
  }

  arrowNext() {
    if (!(this.page + 1 > this.total_pages)) {
      this.page = this.page + 1;
      this.current_page.emit(this.page);
    }
  }

  arrowStart() {
    this.page = 1;
    this.current_page.emit(this.page);
  }

  arrowEnd() {
    this.page = this.total_pages;
    this.current_page.emit(this.page);
  }

  CleanData() {
    this.dataTransaccion.cantidad = '';
    this.dataTransaccion.fondo = '';
    this.dataTransaccion.acciones_id = '';
  }

  openToInfo() {
    this.showMessageToDowload = !this.showMessageToDowload;
  }

  async GenerarOP() {
    let cantidad: any = parseFloat(this.dataTransaccion.cantidad);

    if (cantidad == '' || Number.isNaN(cantidad) || cantidad == '0') {
      this.dataTransaccion.mensajeError = 'Debe ingresar un monto mayor a 0';
      this.dataTransaccion.mensajeValid = true;
      setTimeout(() => {
        this.dataTransaccion.mensajeValid = false;
      }, 2500);
      return;
    }

    if (this.dataTransaccion.fondo === '') {
      this.dataTransaccion.fondoselected = true;
      this.dataTransaccion.mensajeError = 'Seleccione un fondo';
      setTimeout(() => {
        this.dataTransaccion.fondoselected = false;
      }, 2500);
      return;
    }

    if (this.dataTransaccion.operacion === 'comprar') {
      if (cantidad > this.cashBalance) {
        this.dataTransaccion.mensajeError =
          'El monto no debe ser superior al monto disponible';
        this.dataTransaccion.mensajeValid = true;
        setTimeout(() => {
          this.dataTransaccion.mensajeValid = false;
        }, 2500);
        return;
      }
    }

    if (this.dataTransaccion.operacion === 'vender') {
      if (this.dataTransaccion.cantidad_precio > this.cashBalance) {
        this.dataTransaccion.mensajeError =
          'El monto en títulos no debe ser superior al monto disponible';
        this.dataTransaccion.mensajeValid = true;
        setTimeout(() => {
          this.dataTransaccion.mensajeValid = false;
        }, 2500);
        return;
      }
    }

    if (
      this.dataTransaccion.fondo === 'ALTER1+' ||
      this.dataTransaccion.fondo === 'GLOBAL+'
    ) {
      this.notification.show(
        'block',
        '',
        '"' +
          this.dataTransaccion.fondo +
          '"' +
          ' No puede realizar operaciones',
        '',
        'error',
        ''
      );
      return;
    }

    if (this.dataTransaccion.serie === 'A') {
      this.notification.show(
        'block',
        '',
        'No puede usar las series "A" para realizar operaciones',
        '',
        'error',
        ''
      );
      return;
    }

    this.loaderTransparent.show(true);
    this.dataTransaccion.fecha = this.getFechaHoy();
    this.dataTransaccion.contrato = this.idContract;

    if (this.dataTransaccion.operacion === 'comprar') {
      setTimeout(() => {
        this.processstep = 'confirmar';
      }, 1000);
    }
    setTimeout(() => {
      this.processstep = 'confirmar';
    }, 1000);
  }

  async getDatoSerie(event: any, opcion: string) {
    if (opcion === 'element') {
      const item = this.data_to_combo.find(
        ele =>
          ele.fondo === this.dataTransaccion.fondo &&
          ele.serie === this.dataTransaccion.serie
      );
      this.accionesPropiasId = item.acciones_propias_id;
      this.dataTransaccion.acciones_id = item.acciones_propias_id;
    }

    if (opcion === 'select') {
      this.accionesPropiasId = event.target.value;
      const item = this.data_to_combo.find(
        ele => ele.acciones_propias_id === event.target.value
      );
      this.dataTransaccion.fondo = item.fondo;
      this.dataTransaccion.serie = item.serie;
    }

    console.log(this.dataTransaccion);

    if (this.dataTransaccion.operacion === 'vender') {
      if (
        this.dataTransaccion.fondo === 'I+LIQG' ||
        this.dataTransaccion.fondo === 'I+GUBER'
      ) {
        (this.dataTransaccion.operacionventa = true),
          (this.dataTransaccion.tipooperacion = 'monto');
      } else {
        (this.dataTransaccion.operacionventa = false),
          (this.dataTransaccion.tipooperacion = 'titulos');
      }
    } else {
      this.dataTransaccion.operacionventa = true;
    }

    this.loaderTransparent.show(true);
    if (
      this.dataTransaccion.operacion === 'vender' &&
      this.dataTransaccion.fondo != ''
    ) {
      const datafondo = this.data_to_show.find(
        element =>
          element.fund === this.dataTransaccion.fondo &&
          element.serie === this.dataTransaccion.serie
      );
      if (datafondo != undefined) {
        this.dataTransaccion.amount = datafondo.amount;
        this.dataTransaccion.titles = datafondo.titles;
        this.dataTransaccion.totalcost = datafondo.totalcost;
        this.dataTransaccion.value = datafondo.value;
      } else {
        setTimeout(() => {
          this.notification.show(
            'block',
            '',
            'Datos de fondo no encontrado en mis posiciones',
            '',
            'error',
            ''
          );
        }, 1000);
      }
    }

    //obtenemos los datos de la serie, como la comision,fechas y precio
    let opserie = this.dataTransaccion.operacion === 'comprar' ? 'CPA' : 'VTA';
    const data: any = await this.getSerieCV(opserie);
    this.datos_series = data.data;
    this.comprobacion();
    this.loaderTransparent.show(false);
  }

  async transferirChequera() {
    this.loaderTransparent.show(true);
    try {
      this.data_bancos = await this.getChequeraContrato(this.idContract, 'VTA');
    } catch (error) {
      this.data_bancos = error;
    }
    this.processstep = 'recursos';
    this.operacion = 'chequera';
  }

  cambiartipoop(tipo) {
    //this.dataTransaccion.cantidad = '';
    if (tipo === 1) {
      this.dataTransaccion.tipooperacion = 'monto';
    } else {
      this.dataTransaccion.tipooperacion = 'titulos';
    }
    this.cambiarformato();
  }

  //CAMBIAR FORMATO DE LA MONEDA Y VALIDAR CANTIDAD
  cambiarformato() {
    let cantidad = parseFloat(this.dataTransaccion.cantidad);
    let titulos =
      this.dataTransaccion.titles == undefined
        ? 0
        : parseFloat(this.dataTransaccion.titles);
    let amount =
      this.dataTransaccion.amount == undefined
        ? 0
        : parseFloat(this.dataTransaccion.amount);

    this.dataTransaccion.cantidad_final = cantidad;

    if (this.dataTransaccion.operacion === 'comprar') {
      if (this.dataTransaccion.tipooperacion === 'monto') {
        if (cantidad > this.cashBalance) {
          this.dataTransaccion.mensajeError =
            'El monto no debe ser superior al monto disponible';
          this.dataTransaccion.mensajeValid = true;
          setTimeout(() => {
            this.dataTransaccion.mensajeValid = false;
          }, 2500);
          return;
        }

        this.dataTransaccion.cantidad = this.formatearMonto(
          parseFloat(this.dataTransaccion.cantidad)
        );
      }

      if (this.dataTransaccion.tipooperacion === 'titulos') {
        this.dataTransaccion.cantidad_precio =
          this.dataTransaccion.cantidad * this.datos_series[0].precio_titulo;
        if (this.dataTransaccion.cantidad_precio > this.cashBalance) {
          this.dataTransaccion.mensajeError =
            'El monto no debe ser superior al monto disponible';
          this.dataTransaccion.mensajeValid = true;
          setTimeout(() => {
            this.dataTransaccion.mensajeValid = false;
          }, 2500);
          return;
        }
        this.dataTransaccion.cantidad = this.formatearCantidad(
          parseFloat(this.dataTransaccion.cantidad)
        );
      }
    }

    if (this.dataTransaccion.operacion === 'vender') {
      if (this.dataTransaccion.tipooperacion === 'monto') {
        if (cantidad > amount) {
          this.dataTransaccion.mensajeError =
            'La cantidad no debe superar a tus fondos';
          this.dataTransaccion.mensajeValid = true;
          setTimeout(() => {
            this.dataTransaccion.mensajeValid = false;
          }, 2500);
          return;
        }
        this.dataTransaccion.cantidad = cantidad
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, '$&,');
      }
      if (this.dataTransaccion.tipooperacion === 'titulos') {
        if (cantidad > titulos) {
          this.dataTransaccion.mensajeError =
            'El monto no debe ser superior al monto disponible';
          this.dataTransaccion.mensajeValid = true;
          setTimeout(() => {
            this.dataTransaccion.mensajeValid = false;
          }, 2500);
          return;
        }
        this.dataTransaccion.cantidad = this.formatearCantidad(
          parseFloat(this.dataTransaccion.cantidad)
        );
      }
    }
    if (this.dataTransaccion.cantidad == 'NaN') {
      this.dataTransaccion.cantidad = '';
      return;
    }
    this.comprobacion();
  }

  comprobacion() {
    if (
      this.dataTransaccion.fondo !== '' &&
      this.dataTransaccion.cantidad_final > 0
    ) {
      this.validacion = true;
    } else {
      this.validacion = false;
    }
  }

  //OPERACIONES DIRECTO DE LAMNDA DE JONIMA

  async getPositions() {
    this.loaders = true;
    this.collection = '';
    await this.getAll(
      '/contract/' +
        this.idContract +
        '/position?fecha=' +
        this.startDate +
        '&contrato=' +
        this.idContract
    ).subscribe(response => {
      this.data_to_show = response['data'] ? response.data.rows : [];
      //this.posicions = response['data'] ? response.data.totals[0] : {};
      this.paginador(this.data_to_show);

      this.loaders = false;
    });
  }

  async getMovimientos(mov: string) {
    //1 enviamos la opcion de los nuevos movimientos, osea ultimnos 50 movimientos.
    //2 enviamos la opcion de lo antiguis movimientos, osea los movimientos por fecha inicial y fecha final
    this.collection = '/operacion/movimientos';
    let verb = 'POST';
    let data = {
      contrato: this.idContract,
      fechaInicial: this.startDate,
      fechaFinal: this.startDate,
      mov: mov, //
    };
    this.send(data, verb).subscribe(response => {
      const elementosarrauy = response['data'] ? response.data.data : [];
      let fechaHoy = new Date();
      let fechaHoyFormateada = fechaHoy.toISOString().split('T')[0];
      this.movements = elementosarrauy.filter(
        elemento => elemento.fecha_operacion === fechaHoyFormateada
      );
      //this.movements = elementosarrauy;
      this.movements.forEach(element => {
        element.url = `https://compass-descargar-archivo.s3.amazonaws.com/${element.folio}.pdf`;
        element.fecha_ingreso = this.formatDate(element.fecha_ingreso);
        element.fecha_liquidacion = this.formatDate(element.fecha_liquidacion);
        element.fecha_operacion = this.formatDate(element.fecha_operacion);
      });
      setTimeout(() => {
        this.loadersmov = false;
      }, 1000);
    });
  }

  AddAll() {
    if (this.dataTransaccion.fondo === '') {
      this.dataTransaccion.fondoselected = true;
      this.dataTransaccion.mensajeError = 'Seleccione un fondo';
      setTimeout(() => {
        this.dataTransaccion.fondoselected = false;
      }, 2500);
      return;
    }
    if (this.dataTransaccion.operacion === 'vender') {
      //cuando vendemos y queremos seleccionar todo lo que tenemos en titulo o cantidad
      this.dataTransaccion.cantidad =
        this.dataTransaccion.tipooperacion === 'monto'
          ? this.dataTransaccion.amount
          : this.dataTransaccion.titles;
      this.cambiarformato();
    }
    if (this.dataTransaccion.operacion === 'comprar') {
      //cuando compramos y queremos seleccionar todo lo que tenemos en cash
      this.dataTransaccion.cantidad = this.cashBalance;
    }
  }

  /* async getPosiciones(){
    this.loaders = true;
    this.collection = '/operacion/posiciones';
    let verb = 'POST';
    let data = {
      contrato: this.idContract,
      fecha:this.startDate,
    };
    this.send(data, verb).subscribe(response => {
      this.data_to_show = response['data'] ? response.data.data : [];
      this.paginador(this.data_to_show);
      this.loaders = false;
    })
  } */

  async getDataCompraVenta(operacion: string, op: string) {
    // operacion 1: tipo de operacion CPA, 2 tipo VTA

    return new Promise((resolve, reject) => {
      this.collection = '/operacion/datacompraventa';
      let verb = 'POST';
      let data = {
        contrato: this.idContract,
        fechaInicial: this.startDate,
        fechaFinal: this.startDate,
        operacion: operacion,
        op: op,
      };
      this.send(data, verb).subscribe(response => {
        this.data_to_combo = response['data'] ? response.data.data : [];
        resolve(this.data_to_combo);
      });
    });
  }
  async getDataCVP(operacion: string, op: string, contrato: any) {
    // operacion 1: tipo de operacion CPA, 2 tipo VTA

    return new Promise((resolve, reject) => {
      this.collection = '/operacion/datacompraventa';
      let verb = 'POST';
      let data = {
        contrato: contrato,
        fechaInicial: this.startDate,
        fechaFinal: this.startDate,
        operacion: operacion,
        op: op,
      };
      this.send(data, verb).subscribe(response => {
        resolve(response['data'] ? response.data.data : []);
      });
    });
  }
  async getSerieCV(operacion: string) {
    return new Promise((resolve, reject) => {
      this.collection = '/operacion/seriecomven';
      let verb = 'POST';
      let data = {
        contrato: this.idContract,
        operacion: operacion,
        acciones_propias_id: this.accionesPropiasId,
      };
      this.send(data, verb).subscribe(response => {
        resolve(response['data']);
      });
    });
  }

  private formatDate(date) {
    let partesFecha = date.split('-'); // Dividir la fecha en partes (año, mes, día)
    return partesFecha[2] + '/' + partesFecha[1] + '/' + partesFecha[0];
  }
}
