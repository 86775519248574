import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { appRoutesNames } from 'src/app/app-routing/app.routes.names';
import { ImenuItems } from 'src/app/common/dtos/sidebarItemsInterface';
import { SessionService } from 'src/app/util/session/session.service';

@Component({
  selector: 'app-user-options',
  templateUrl: './user-options.component.html',
  styleUrls: ['./user-options.component.scss'],
})
export class UserOptionsComponent {
  constructor(private session: SessionService, public router: Router) {}

  public options: ImenuItems[] = [
    {
      name: 'Ajustes',
      icon: '../../../assets/icons/i-settings-menu.svg',
      route: appRoutesNames.AJUSTES,
    },
    {
      name: 'Cerrar Sesión',
      icon: '../../../assets/icons/i-logout-menu.svg',
      route: '../' + appRoutesNames.LOGIN,
    },
  ];

  routerToPath(item: ImenuItems): void {
    switch (item.name) {
      case 'Ajustes':
        this.router.navigate(['dashboard/' + item.route]);
        break;
      case 'Cerrar Sesión':
        this.session.destroy();
        break;
    }
  }
}
