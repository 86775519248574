<div class="col-12">
  <ng-container>
    <div
      class="card accordion accordion-flush mb-5 p-4"
      id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed spanAlternativeSubTitle weight-500"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseOne"
            aria-expanded="false"
            aria-controls="flush-collapseOne">
            <div class="w-100 d-flex justify-content-between">
              <span>1. Antecedentes de la persona jurídica declarante</span>
              <span class="material-symbols-outlined oficial-blue">
                expand_more
              </span>
            </div>
          </button>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionFlushExample">
          <div class="weight-500">
            <app-form-legal-person
              (formReady)="subformReady($event)"></app-form-legal-person>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed spanAlternativeSubTitle weight-500"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo"
            aria-expanded="false"
            aria-controls="flush-collapseTwo">
            <div class="w-100 d-flex justify-content-between">
              <span>2. Identificación de los beneficiarios finales</span>
              <span class="material-symbols-outlined oficial-blue">
                expand_more
              </span>
            </div>
          </button>
        </h2>
        <div
          id="flush-collapseTwo"
          class="accordion-collapse collapse spanAlternativeSubTitle weight-500"
          data-bs-parent="#accordionFlushExample">
          <div class="">
            <app-form-beneficiarios-finales
              (formReady)="
                subformReady($event)
              "></app-form-beneficiarios-finales>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h2 class="accordion-header">
          <button
            class="accordion-button collapsed spanAlternativeSubTitle weight-500"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseThree"
            aria-expanded="false"
            aria-controls="flush-collapseThree">
            <div class="w-100 d-flex justify-content-between">
              <span>
                3. Antecedentes de la persona que realiza la presente
                declaración</span
              >
              <span class="material-symbols-outlined oficial-blue">
                expand_more
              </span>
            </div>
          </button>
        </h2>
        <div
          id="flush-collapseThree"
          class="accordion-collapse collapse spanAlternativeSubTitle weight-500"
          data-bs-parent="#accordionFlushExample">
          <div class="">
            <app-form-client-data
              (formReady)="subformReady($event)"></app-form-client-data>
            <div class="d-flex mt-5 col-4 justify-content-end">
              <button
                class="btn btn-primary"
                (click)="sendData()"
                [disabled]="myForm.invalid"
                >
                Enviar para firma
              </button>
              <!-- [disabled]="myForm.invalid" -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
