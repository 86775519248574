import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  /**
   * @property {Boolean} Show or hide succes icon
   * @public
   */
  showLoader: boolean = false;

  constructor(private loader: LoaderService) {}

  ngOnInit() {
    this.loader.currentMessage.subscribe(response => {
      this.showLoader = response.showLoader;
    });
  }
}
