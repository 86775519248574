import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcumComponent } from './breadcum.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [BreadcumComponent],
  exports: [BreadcumComponent],
  imports: [CommonModule,FormsModule]
})
export class BreadcumModule { }
