import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationService } from './notification.service';
import { SanitizeHtmlPipe } from '../pipes/sanitizerUrl.pipe';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  /**
   * @property {string} section Title when the notice show up
   * @public
   */
  title: any;
  /**
   * @property {string}  Path to redirect
   * @public
   */
  route: string;
  /**
   * @property {string} SHOW HIDE card with CSS property
   * @public
   */
  type: string = 'none';
  /**
   * @property {string} Message to show in button
   * @public
   */
  textButton: string;
  /**
   * @property {String} Select icon type : error, check
   * @public
   */
  iconType: string = '';
  /**
   * @property {String} Route navigation bottom
   * @public
   */
  routeButton: boolean = false;

  /**
   * @property {String} Subtitle text error or success
   * @public
   */

  subtitle: string = '';

  constructor(
    private notification: NotificationService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}
  /**
   * Lifecycle hook
   */
  ngOnInit() {
    let notification = document.getElementById('myNotification')!;

    this.notification.currentMessage.subscribe(response => {
      window.onclick = function (event) {
        if (event.target == notification) {
          notification.style.display = 'none';
        }
      };

      this.route = response.route;
      this.type = response.type;
      this.title =  response.title;
      this.textButton = response.textButton;
      this.iconType = response.iconType;
      this.routeButton = response.routeButton;

      if (response.iconType === 'check_sendcode') {
        this.subtitle = 'Procesando transacción';
      }
      if (response.iconType === 'error_sendcode') {
        this.subtitle = 'Hubo un problema con la operación';
      }



      notification.style.display = this.type;
    });
    
  }
  /**
   * @description Allow hide the notice immediately
   */
  close(): void {
    let notification = document.getElementById('myNotification')!;
    notification.style.display = 'none';
  }
  /**
   * @description Redirect button
   */
  redirectTo() {
    this.close();
    this.router.navigate([this.routeButton]);
  }

  change() {
    this.close();
  }
}
