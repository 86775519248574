import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NotificationService {
  /**
   * @property {BehaviorSubject} A new instance from Behavior Subject
   * @private
   */
  private messageSource = new BehaviorSubject<any>({
    type: '',
    route: '',
    title: '',
    textButton: '',
    iconType: '',
    routeButton: '',
  });
  /**
   * @property {Observable} Observable object
   * @public
   */
  currentMessage = this.messageSource.asObservable();
  constructor() {}

  show(
    type: string,
    route: string,
    title: string,
    textButton: string,
    iconType: string,
    routeButton: string
  ) {
    if (type === 'block') {
      //type = 'flex';
    }

    this.messageSource.next({
      type: type,
      route: route,
      title: title,
      textButton: textButton,
      iconType: iconType,
      routeButton: routeButton,
    });
  }
}
