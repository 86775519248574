<app-breadcum [data]="dataBreadcum"></app-breadcum>
<div class="d-flex mt-3 bg-white me-4 ms-4">
  <div class="container">
    <div class="content">
      <div class="row">
        <app-select-list
          [showArrow]="true"
          [title]="title"
          (valueResponse)="getContactData($event)"></app-select-list>
      </div>
    </div>
  </div>
</div>

<div
  class="fadeIn d-flex row-column-custom mt-3 bg-white me-4 ms-4"
  [ngClass]="{ 'box-heigth': processstep === 'visualizar' }">
  <ng-container *ngIf="processstep === 'visualizar'">
    <div class="container col-xl-7 col-lg-6 col-md-12">
      <div class="content">
        <div class="row">
          <div class="col-12">
            <div id="movementsDiv" class="row d-lg-block mt-4">
              <div
                class="col-12 d-flex justify-content-between mb-2 btn-group-left">
                <div class="col-6">
                  <p class="text-grey fs-16">Posición en fondos</p>
                </div>
                <div class="col-6 text-end">
                  <button
                    class="btn-alternative ms ms-4"
                    (click)="actualizar()">
                    <object
                      class="me-2"
                      data="assets/icons/i-refresh.svg"
                      type=""></object>
                    Actualizar
                  </button>
                </div>
              </div>
              <div class="col-12">
                <div class="card p-0 mb-5">
                  <div class="scroll-table">
                    <table
                      id="movementsTable"
                      class="table tablefondos table-borderless">
                      <thead>
                        <tr>
                          <th>Fondo</th>
                          <th>Serie</th>
                          <th>Títulos</th>
                          <th>Importe</th>
                          <th>
                            <div class="d-flex flex-column">
                              <div>Precio por</div>
                              <div>titulo</div>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngIf="loaders">
                          <tr>
                            <td colspan="5">
                              <div class="d-flex justify-content-center mt-3">
                                <div class="spinner-border" role="status">
                                  <span class="visually-hidden"
                                    >Loading...</span
                                  >
                                </div>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-container
                          *ngIf="data_to_show.length === 0 && !loaders">
                          <tr>
                            <td colspan="5">
                              <div class="text-center">
                                <p>No hay posiciones a mostrar</p>
                              </div>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-container
                          *ngFor="let item of data_to_show; let i = index">
                          <tr
                            [ngClass]="{ isSelected: item.isSelected }"
                            *ngIf="
                              i + 1 <= limit * page &&
                              i + 1 > limit * (page - 1)
                            ">
                            <td
                              (click)="selectElement(item)"
                              class="fondo-item">
                              {{ item.fund }}
                            </td>
                            <td>{{ item.serie }}</td>
                            <td>
                              {{ item.titles.toString() | number : '1.0-0' }}
                            </td>
                            <td>
                              $&nbsp;{{
                                item.amount.toString() | number : '1.2-2'
                              }}
                            </td>
                            <td>$&nbsp;{{ item.price }}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="d-flex flex-row align-items-center gap-3 align-self-end"
                    *ngIf="total_pages > 1">
                    <p class="m-0 spanAlternativeSubTitle">Páginas</p>
                    <button class="btn btn-primary" (click)="arrowStart()">
                      <img
                        src="assets/icons/i-arrow-left-white.svg"
                        alt="Flecha izquierda" />
                    </button>
                    <button class="btn" (click)="arrowPrevious()">
                      <img
                        src="assets/icons/i-arrow-left.svg"
                        alt="Flecha izquierda" />
                    </button>
                    <p class="m-0 spanAlternativeSubTitle">
                      <span>{{ page }}</span> de <span>{{ total_pages }}</span>
                    </p>
                    <button class="btn" (click)="arrowNext()">
                      <img
                        src="assets/icons/i-arrow-right.svg"
                        alt="Flecha derecha" />
                    </button>
                    <button class="btn btn-primary" (click)="arrowEnd()">
                      <img
                        src="assets/icons/i-arrow-right-white.svg"
                        alt="Flecha derecha" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 d-flex">
            <p class="oficial-blue f-16 weight-700 mt-3 mb-0">
              EFECTIVO DISPONIBLE
              <span class="ms-3">
                <span>${{ cashBalance.toString() | number : '1.2-2' }}</span>
              </span>
            </p>
          </div>
          <div class="col-6 text-end">
            <button
              class="btn-alternative lg"
              type="button"
              (click)="transferirChequera()">
              <object
                class="me-2"
                data="assets/icons/i-importexport.svg"
                type=""></object>
              Transferir chequera
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="container col-xl-5 col-lg-6 col-md-12">
      <div class="content">
        <div class="row">
          <div class="col-12">
            <div class="mb-5 row">
              <div class="col-6 d-flex">
                <button
                  class="btn-operation"
                  id="comprar"
                  type="button"
                  [ngClass]="{
                    active: dataTransaccion.operacion === 'comprar'
                  }"
                  (click)="acction(1)">
                  Compra
                </button>
                <button
                  class="btn-operation"
                  id="vender"
                  type="button"
                  [ngClass]="{ active: dataTransaccion.operacion === 'vender' }"
                  (click)="acction(2)">
                  Venta
                </button>
              </div>
            </div>
            <form class="col-xxl-11 col-lx-12 col-lg-12 col-md-10 col-12">
              <div class="mb-3 row">
                <div class="col-lg-7 col-md-8 col-sm-7">
                  <label
                    for="fondo"
                    class="form-label text-grey fs-14 weight-500 d-flex">
                    <span> Fondo </span>
                    <div class="ms-1 pos-rel">
                      <span
                        class="btn-i-info material-symbols-outlined pointer alertIcon"
                        (click)="openToInfo()">
                        info
                      </span>
                      <ng-container *ngIf="showMessageToDowload">
                        <div class="alertDowload">
                          <span>
                            Te recomendamos que debes seleccionar un fondo para
                            poder continuar
                          </span>
                        </div>
                      </ng-container>
                    </div>
                  </label>
                  <select
                    id="fondo"
                    class="form-select options-with-line"
                    [ngClass]="{
                      'is-invalid': dataTransaccion.fondoselected,
                      'ng-touched': dataTransaccion.fondoselected
                    }"
                    name="fondo"
                    [(ngModel)]="dataTransaccion.acciones_id"
                    (change)="getDatoSerie($event, 'select')">
                    <option [value]="''" selected>Seleccionar fondo</option>
                    <ng-container *ngFor="let item of data_to_combo">
                      <option value="{{ item.acciones_propias_id }}">
                        {{ item.fondo }} - {{ item.serie }}
                      </option>
                    </ng-container>
                  </select>
                </div>
                <div
                  class="col-lg-5 col-md-4 col-sm-5 d-flex align-items-end justify-content-end">
                  <button
                    type="button"
                    class="btn-i-info mb-2 mt-2 mt-sm-0"
                    (click)="CleanData()">
                    <object
                      class="me-2"
                      data="assets/icons/i-borrar-blue.svg"
                      type=""></object>
                    <span class="mb-2"> Borrar datos </span>
                  </button>
                </div>
              </div>
              <div class="mb-3 row">
                <div class="col-8">
                  <label
                    for="tipoinversion"
                    class="form-label text-grey fs-14 weight-500"
                    >Tipo de inversión</label
                  >
                  <div class="d-flex">
                    <div
                      class="form-check me-5"
                      *ngIf="dataTransaccion.operacionventa">
                      <label class="form-check-label">
                        Monto
                        <input
                          class="form-check-input"
                          id="monto"
                          name="tipooperacion"
                          type="radio"
                          value="monto"
                          [(ngModel)]="dataTransaccion.tipooperacion"
                          (click)="cambiartipoop(1)" />
                      </label>
                    </div>
                    <div class="form-check">
                      <label class="form-check-label">
                        Títulos
                        <input
                          class="form-check-input"
                          id="titulos"
                          name="tipooperacion"
                          type="radio"
                          value="titulos"
                          [(ngModel)]="dataTransaccion.tipooperacion"
                          (click)="cambiartipoop(2)" />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mb-3 row">
                <label
                  for="cantidad"
                  class="form-label text-grey fs-14 weight-500"
                  >Cantidad</label
                >
                <div class="d-flex position-relative">
                  <span
                    class="input-text"
                    id="cantidad"
                    *ngIf="dataTransaccion.tipooperacion === 'monto'"
                    >$</span
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="cantidad"
                    name="cantidad"
                    [(ngModel)]="dataTransaccion.cantidad"
                    [ngClass]="{ 'is-invalid': dataTransaccion.mensajeValid }"
                    (blur)="cambiarformato()" />
                  <button class="btn-todo" type="button" (click)="AddAll()">
                    Todo
                  </button>
                </div>
                <div
                  class="alert-feedback"
                  [ngClass]="{
                    dblock:
                      dataTransaccion.mensajeValid ||
                      dataTransaccion.fondoselected
                  }">
                  <object
                    class="me-2 icon-width"
                    data="assets/icons/i-xclose.svg"
                    type=""></object
                  >{{ dataTransaccion.mensajeError }}
                </div>
              </div>
              <div class="mb-3 row group-line-check">
                <button
                  class="btn-alternative-alternative btn-xxl plr-20"
                  type="button"
                  (click)="GenerarOP()"
                  [disabled]="!validacion">
                  Generar operación
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="processstep === 'recursos'">
    <app-recursos
      [data]="data_bancos"
      [money]="cashBalance"
      [operacion]="operacion"
      [contrato]="idContract"
      [dataTransferencia]="dataTransaccion"
      (dataToParent)="receiveDataFromChild($event)"></app-recursos>
  </ng-container>
  <ng-container *ngIf="processstep === 'confirmar'">
    <app-confirmar
      [data]="dataTransaccion"
      [series]="datos_series"
      [contrato]="idContract"
      (dataToConfirm)="ReceiveDataFromConfirmar($event)"></app-confirmar>
  </ng-container>
</div>

<div
  class="fadeIn d-flex mt-3 bg-white me-4 ms-4"
  *ngIf="processstep === 'visualizar'">
  <div class="container">
    <div class="content">
      <div class="row">
        <div class="col-12">
          <p class="titleeEfective">Órdenes del día</p>
        </div>
        <div class="col-12">
          <div class="card p-0">
            <div class="scroll-table">
              <table
                id="movementsTable"
                class="table t-orders table-borderless tablefondos">
                <thead>
                  <tr>
                    <th>Operación</th>
                    <th>Estado</th>
                    <th>
                      <div class="d-flex flex-column">
                        <div>Fecha de</div>
                        <div>aplicación</div>
                      </div>
                    </th>
                    <th>Fondo</th>
                    <th>Serie</th>
                    <th>
                      <div class="d-flex flex-column">
                        <div>Precio por</div>
                        <div>titulo</div>
                      </div>
                    </th>
                    <th>Títulos</th>
                    <th>Importe</th>
                    <th>Folio</th>
                    <th>
                      <div class="d-flex flex-column">
                        <div>Fecha de</div>
                        <div>operación</div>
                      </div>
                    </th>
                    <th>
                      <div class="d-flex flex-column">
                        <div>Fecha de</div>
                        <div>liquidacion</div>
                      </div>
                    </th>
                    <th>Detalle</th>
                    <th>Comprobante</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="loadersmov">
                    <tr>
                      <td colspan="15">
                        <div class="d-flex justify-content-center mt-3">
                          <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="movements.length === 0 && !loadersmov">
                    <tr>
                      <td colspan="15">
                        <div class="text-center">
                          <p>No hay ordenes para mostrar</p>
                        </div>
                      </td>
                    </tr>
                  </ng-container>
                  <ng-container *ngFor="let item of movements">
                    <tr>
                      <td>{{ item.operacion }}</td>
                      <td>{{ item.estatus }}</td>
                      <td>{{ item.fecha_ingreso }}</td>
                      <td>{{ item.fondo }}</td>
                      <td>{{ item.serie }}</td>
                      <td>
                        {{ item.precio == 0 ? '' : '$&nbsp;' + item.precio }}
                      </td>
                      <td>
                        {{
                          item.titulos == 0
                            ? ''
                            : (item.titulos.toString() | number : '1.0-0')
                        }}
                      </td>
                      <td>
                        $&nbsp;{{ item.importe.toString() | number : '1.2-2' }}
                      </td>
                      <td>{{ item.folio }}</td>
                      <td>{{ item.fecha_operacion }}</td>
                      <td>{{ item.fecha_liquidacion }}</td>
                      <td>
                        <a class="" target="_blank" href="{{ item.url }}"
                          >Comprobante</a
                        >
                      </td>
                      <td>{{ item.comprobante }}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
