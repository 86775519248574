import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectListComponent } from './select-list.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [SelectListComponent],
  exports:[SelectListComponent],
  imports: [CommonModule,FormsModule],
})
export class SelectListModule { }
