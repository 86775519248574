/**
 * @author       Eduardo Leonardo Torres Guevara <eduardo.tg@ix.agency>
 * @copyright    2022 IX Agency.
 * @lastmodifiedDate 14.11.2022 13:00
 */
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
// Handler session
import { SessionService } from '../session/session.service';
@Injectable()
export class AccessGuard implements CanActivate {
  /**
   *
   * @param router Reference to Router Angular class
   * @param session Reference to Session Service class
   * @class AccessGuard
   * @constructor
   */
  constructor(private router: Router, private session: SessionService) {}
  /**
   * @description Check if exist a token in session
   * @param next Contains the information about a route associated with a component loaded
   * @param state Represents the state of the router at a moment
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.session.inSession()) {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }
}
