<div class="fadeIn">
  <div class="row logo_phone">
    <img
      class="logo"
      src="/assets/images/logo-blanco.png"
      alt="logo-compasss-blanco"
      height="90px"
      width="250px" />
  </div>
  <div class="public-pages d-flex justify-content-center align-items-center">
    <div class="d-flex w-100 justify-content-center">
      <div
        class="fadeIn card card-login col-xxl-3 col-xl-4 col-lg-4 col-md-8 col-10">
        <ng-container *ngIf="showEmail">
          <div class="d-flex justify-content-center mb-3">
            <h1>Ingresa tu email de cliente</h1>
          </div>
          <form [formGroup]="myForm" (ngSubmit)="onSubmitEmail(myForm)">
            <label for="mail">Email de cliente</label>
            <input
              type="email"
              formControlName="email"
              class="form-control mb-30" />
            <button
              type="submit"
              class="btn btn-primary w-100"
              [disabled]="myForm.invalid">
              Continuar
            </button>
            <div class="d-flex justify-content-center mt-3">
              <span class="material-symbols-outlined arrow">
                chevron_left
              </span>
              <a routerLink="/login" routerLinkActive="active"> Volver </a>
            </div>
          </form>
        </ng-container>
        <ng-container *ngIf="showCode">
          <h1 class="mb-1">Ingresar código</h1>
          <span class="spanAlternativeSubTitle weight-300 mb-3"
            >Por favor rellena los siguientes datos</span
          >
          <form [formGroup]="myCodeForm" (ngSubmit)="onSubmitCode(myCodeForm)">
            <span
              class="spanAlternativeSubTitle secLabel weight-300 bigger"
              for="mail"
              >Código enviado al correo</span
            >
            <input
              type="text"
              formControlName="code"
              [attr.autocomplete]="'off'"
              aria-autocomplete="none"
              class="form-control code-input mb-4" />
            <h1 class="mb-3">Crear una nueva contraseña</h1>
            <span class="spanAlternativeSubTitle secLabel weight-300" for="new"
              >Contraseña</span
            >
            <input
              type="password"
              id="password"
              formControlName="password"
              [attr.autocomplete]="'off'"
              aria-autocomplete="none"
              class="form-control mb-2" />
            <span
              class="material-symbols-outlined buttton-eye"
              (click)="showPass()">
              {{ eyeIcon }}
            </span>
            <span class="spanAlternativeSubTitle secLabel weight-300" for="new"
              >Confirmar contraseña</span
            >
            <input
              type="password"
              id="passwordConfirm"
              formControlName="repeatePassword"
              class="form-control mb-2" />
            <span
              class="material-symbols-outlined buttton-eye"
              (click)="showPassConfirm()">
              {{ eyeIconConfirm }}
            </span>
            <div class="password-requirements">
              <span> Contraseña debe contener al menos: </span>
              <ul class="">
                <li>8 caracteres</li>
                <li>1 número</li>
                <li>1 mayúscula</li>
                <li>1 minúscula</li>
                <li>1 caracter especial</li>
              </ul>
            </div>

            <div class="d-flex buttons_code_public">
              <button
                type="button"
                class="btn btn-alternative w-45 one-line"
                (click)="sendCode()">
                Reenviar código
              </button>
              <button
                type="submit"
                class="btn btn-primary w-45 ml-3"
                [disabled]="myForm.invalid">
                Continuar
              </button>
            </div>
            <div class="d-flex justify-content-center mt-3">
              <span class="material-symbols-outlined arrow">
                chevron_left
              </span>
              <a routerLink="/login" routerLinkActive="active"> Volver </a>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </div>
</div>
