import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BaseService } from 'src/app/base/base.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseService implements OnInit, OnDestroy {
  /**
   * @property {FormGroup} Form get only code
   * @public
   */
  public codeForm: FormGroup;
  /**
   * @property {boolean} Validate HIDE SHOW code card
   * @public
   */
  public showCodeForm: boolean = false;
  /**
   * @property {String} Icon type/name
   * @public
   */
  public eyeIcon = 'visibility';
  /**
   * @property {string} Save session user from signin service
   * @private
   */
  private sessionUser: string = '';

  subscription: Subscription;

  ngOnInit(): void {
    this.loader.show(true);
    let authSubscription = this.auth.user$;
    this.subscription = authSubscription.subscribe(response => {
      if (response?.email) {
       // console.log(1);
        this.router.navigate(['/login-two-factor']);
      } else {
       // console.log(2);
        this.auth.loginWithRedirect();
      }
      this.subscription.unsubscribe();
    });
    this.loader.show(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  /**
   * @description Signin service
   * @returns void
   */
  sendCode(form: FormGroup): void {
    this.collection = '/auth/signin/mfa';
    let verb = 'POST';
    this.loader.show(true);

    let data = {
      username: this.myForm.value.user,
      answer: form.value.code.toString(),
      session: this.sessionUser,
    };
    this.send(data, verb).subscribe(response => {
      if (response.message == 'Success') {
        localStorage.setItem('email', this.myForm.value.user);
        this.session.create(response.data);
      } else {
        this.notification.show(
          'block',
          '',
          'Código incorrecto.',
          '',
          'error',
          ''
        );
      }
      this.loader.show(false);
    });
  }

  /**
   * @description Send one more time 2FA code
   * @returns void
   */
  reSendCode(): void {
    this.collection = '/auth/signin';
    let verb = 'POST';
    this.loader.show(true);
    let data = {
      user: this.myForm.value.user,
      password: this.myForm.value.password,
    };
    this.send(data, verb).subscribe(response => {
      if (response.message == 'Success') {
        this.sessionUser = response.data.session;
        this.notification.show(
          'block',
          '',
          'Código enviado.',
          '',
          'check',
          '/login'
        );
      }

      this.loader.show(false);
    });
  }
}
