<app-breadcum [data]="dataBreadcum"></app-breadcum>
<app-contracts
  [showDateRange]="true"
  [title]="'Valor de portafolio'"
  (valueResponse)="getContactData($event)"></app-contracts>

  <div class="contt mt-4 mb-4 ms-4 me-4">
    <div class="row">
      <div id="graphTableDiv" class="row mb-2">
        <div class="col-12">
          <div class="scroll-table" id="scroll">
            <table id="graphTable" class="table table-borderless mb-0" class="mb-3">
              <tbody>
                <tr class="totals_titles">
                  <td>Valor</td>
                  <td>Saldo en efectivo</td>
                  <td>Total</td>
                </tr>
                <tr class="totals_subTitles" *ngFor="let item of generals">
                  <td>$ {{ item.value.toString() | number : '1.2-2' }}</td>
                  <td>$ {{ item.cashBalance.toString() | number : '1.2-2' }}</td>
                  <td>$ {{ item.total.toString() | number : '1.2-2' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="withoutData" *ngIf="data.length === 0">
            <span>No hay datos disponibles </span>
          </div>
        </div>
      </div>    
    </div>
  </div>

  <div class="">
    <div class="row ms-4 me-4">
      <div class="col-lg-6 col-md-12 ps-0 pe-0 pe-xl-2 mb-3">
        <div class="contt">
          <div class="chart-title mb-2">
            <span class="card-top-out-title">Gráfica</span>
            <span class="card-top-out-title mobil">&nbsp; {{ idContract }}</span>
          </div>
          <div class="card">
            <div class="scroll-table card-table-height" id="card">
              <ng-container *ngIf="showChart">
                <canvas
                  id="porfolioChart"
                  baseChart
                  width="500"
                  height="250"
                  [data]="lineChartData"
                  [options]="lineChartOptions"
                  [type]="'line'"></canvas>
              </ng-container>
            </div>
            <div class="withoutData" *ngIf="data.length === 0">
              <span>No hay datos disponibles </span>
            </div>
          </div>
        </div>
      </div>
    
      <div class="col-lg-6 col-md-12 ps-0 pe-0 ps-xl-2 mt-lg-0 mt-md-5 mb-100">
        <div class="contt flex-row" id="mainTable">
          <div class="col-12 col-ms-12 col-md-6 chart-title d-flex align-items-center">
            <div>
              <span class="card-top-out-title table">Valor del portafolio</span>
              <!-- <span class="card-top-out-title table mobile"
                >&nbsp; {{ idContract }}</span
              > -->
            </div>
          </div>

          <div class="col-12 col-ms-12 col-md-6 chart-title d-flex justify-content-end">
            <button
              *ngIf="!isDownloading"
              class="btn-alternative"
              (click)="printPDF()">
              <span class="material-symbols-outlined icon me-2"> download </span>
              <span>Exportar</span>
            </button>
          </div>
          <div class="col-12 card pe-0 ps-0">
            <div class="scroll-table" id="card">
              <table id="porfolioTable" class="table table-borderless table-portafolio">
                <thead>
                  <tr>
                    <th>Fecha</th>
                    <th>Valor</th>
                    <th>Saldo en efectivo</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of data">
                    <td class="text-start">{{ item.date }}</td>
                    <td class="text-start">$ {{ item.value.toString() | number : '1.2-2' }}</td>
                    <td class="text-start">$ {{ item.cashBalance.toString() | number : '1.2-2' }}</td>
                    <td class="text-start">$ {{ item.total.toString() | number : '1.2-2' }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="withoutData" *ngIf="data.length === 0">
                <span>No hay datos disponibles </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
