import { Component, OnInit } from '@angular/core';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  /**
   * @property {Boolean} Show or hide succes icon
   * @public
   */
  showModal: boolean = false;

  /**
   * @property {String} Text modal
   * @public
   */
  body: string = '';

  url: string = '';

  constructor(private modal: ModalService) {}

  ngOnInit() {
    this.modal.currentMessage.subscribe(response => {
      this.showModal = response.showModal;
      this.body = response.body;
      this.url = response.url;
    });
  }

  public close() {
    this.showModal = false;
  }
  public aceptar(){
    this.showModal = false;
    setTimeout(() => {
      window.open(
        this.url
      )
    }, 500);

  }
}
