
    <div class="container">
        <div class="content">
            <div class="row">
                <div class="col-12 mb-4">
                    <p class="subtitle"><object class="me-2" *ngIf="stepStatus" data="assets/icons/i-clock-green.svg" type=""></object>{{stepTexts.titleSuper}}</p>
                    <span class="description">{{stepTexts.textSuper}}<strong *ngIf="!stepStatus">{{stepTexts.textSuperStrong}}.</strong><a target="_blank" [routerLink]="" *ngIf="stepStatus">{{stepTexts.textSuperStrong}}</a></span>
                    <br>
                    <span class="letter-green fs-16 weight-700" *ngIf="stepStatus">Operación realizada</span>
                </div>
                <hr>
                <app-proceso [data]="data" [accion]="accion"></app-proceso>
                <hr>
                <div class="row mt-3"  *ngIf="!stepStatus">
                    <div class="col-12 mb-4">
                        <p class="subtitle"><object class="me-2 icon-width" data="assets/icons/i-exclamacion-abajo.svg" type=""></object>Importante</p>
                        <span class="description">Antes de presionar Aceptar, verifica que los datos estén correctos.</span>
                    </div>
                    <div class="col-3 align-items-start">
                        <button type="button" class="btn btn-cancelar" (click)="BacktoPageCancel()">Cancelar</button>
                    </div>
                    <div class="col-9 small-btns text-end">
                       <!--  <button type="button" class="btn btn-alternative plr-60 me-3" (click)="refreshPage()">Terminar</button> -->
                        <button type="button" class="btn btn-alternative plr-60 me-3" (click)="BacktoPage()">Regresar</button>
                        <button class="btn-alternative-alternative plr-60" type="button" (click)="Aceptar()">Aceptar</button>
                    </div>
                </div>
                <div class="row mt-4" *ngIf="stepStatus">
                    <div class="col-4 mb-3">
                        <p class="subtitle">Estatus de la orden</p>
                        <span class="status-alert">Aplicada</span>
                    </div>
                    <!-- <div class="col-4 mb-3">
                        <p class="subtitle">Fecha de solicitud</p>
                        <span class="description">{{data.fecha_solicitud}}</span>
                    </div> -->
                    <div class="col-4 mb-3">
                        <p class="subtitle">Folio de operación</p>
                        <span class="description">{{data.folio_movimiento === 'agregar_efectivo_disponible'? 'Agregar a efectivo disponible': data.folio_movimiento}}</span>
                    </div>
                    <ng-container *ngIf="data.operacion === 'Transferencia'">
                        <div class="col-4 mb-3">
                            <p class="subtitle">Cuenta destino</p>
                            <span class="description">{{data.cuentadestino}}</span>
                        </div>
                    </ng-container>
                    <div class="col-12">
                        <div class="alert-info">
                            <object class="me-2" data="assets/icons/i-clock-blue.svg" type=""></object><span>{{stepTexts.textStatus}}</span>
                        </div>
                    </div>
                    <div class="col-12 text-end mb-3 mt-3">
                        <button type="button" class="btn btn-alternative plr-60 me-3" (click)="refreshPage()">Terminar</button>
                        <button type="button" class="btn-alternative-alternative" *ngIf="data.operacion === 'vender'" (click)="recursosDestino()">Destino de los recursos</button>
                    </div>
                </div>
            </div>
        </div>
    </div>