/**
 * @author       Eduardo Leonardo Torres Guevara <eduardo.tg@ix.agency>
 * @copyright    2023 IX Agency.
 * @lastmodifiedDate 18.01.2023 14:32
 */
import { Component, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import Chart from 'chart.js/auto';
import { BaseService } from 'src/app/base/base.service';
import { positionInterface } from 'src/app/common/dtos/contractsDTOs';
import { ExportService } from 'src/app/util/exportFiles/export.service';

@Component({
  selector: 'app-posicion',
  templateUrl: './posicion.component.html',
  styleUrls: ['./posicion.component.scss'],
})
export class PosicionComponent extends BaseService implements OnInit {
  /**
   * @property {Array} List for rows table
   * @public
   */
  public data: positionInterface[] = [];
  /**
   * @property {Object} Selected bussines data
   * @private
   */
  private bussines: any = {};
  /**
   * @property {Array} List for generals data
   * @public
   */
  public generals: any = {};
  /**
   * @property {Boolean} Show Hide chart while fill data
   * @public
   */
  public showChart = false;
  /**
   * @property {string} Highlight value seleected
   * @public
   */
  public highlight = '';
  public highlightserie = '';
  /**
   * @property {string} Id Contract for query params
   * @private
   */
  private idContract: string = '';
  /**
   * @property {string} Date for query params
   * @private
   */
  private startDate: string = '';

  public titleChartTable: string = 'Totales';

  public lastUpdate = '';

  globalChart: Chart;

  isDownloading = false;

  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
        onClick: e => {},
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => {
            return this.formatLabelChartTooltip(tooltipItem);
          },
        },
      },
    },
  };

  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [
          '#002FA7',
          '#7CAAF3',
          '#0D61E7',
          '#38A888',
          '#B8E994',
          '#BFBFBF',
          '#FA983A',
          '#EB2F06',
          '#3BBFE2',
        ],
      },
    ],
  };

  public permissionLink:boolean = false;

  ngOnInit(): void {
    this.loaderTransparent.show(true);
    this.dataBreadcum = [{
      breadcum: ['Home', 'Portal de clientes','Resumen del portafolio', 'Posición'],
      title: 'Posición',
      subTitle : '',
      icon:false
     }];

     let email = this.session.getEmail();
    this.getAll('/contract?email=' + email).subscribe(response => {
      let arrayTransaccion:any = [...response.data.permission];
      arrayTransaccion = arrayTransaccion.slice(6,8);
     if (arrayTransaccion[0].allow === true && arrayTransaccion[1].allow === true) {
       this.permissionLink = true;
     }
      
    });

  }

  public getContactData(contactData) {
    this.loaderTransparent.show(true);
    this.showChart = false;
    this.titleChartTable = 'Totales';
    this.bussines = contactData.bussines;
    this.idContract = contactData.contract;
    this.startDate = contactData.date;
    this.getAll(
      '/contract/' +
        this.idContract +
        '/position?fecha=' +
        this.startDate +
        '&contrato=' +
        this.idContract
    ).subscribe(response => {
      this.loaderTransparent.show(false);
      console.log(response['data']);
      let data = response['data'] ? response.data.rows : [];
      this.data = data.map(item => ({
        ...item,
        valueNumber: Number(item.value),
        priceFormartZeros: Number(item.price).toFixed(6) || '0.000000',
        unitCostFormartZeros: Number(item.unitCost).toFixed(6) || '0.000000',
      }));

      this.generals = response['data'] ? response.data.totals[0] : {};
      if (this.data.length > 0) {
        this.pieChartData.labels = response.data.rows.map(
          elent => elent.fund + '  ' + Number(elent.percent).toFixed(2) + '%'
        );
        this.pieChartData.datasets[0].data = response.data.rows.map(elent =>
          Number(elent.amount).toFixed(2)
        );

        this.showChart = true;
      }
      this.lastUpdate = this.getLastUpdate(response.data.lastUpdate);

      this.loaderTransparent.show(false);
    });
  }

  public showSerie(item): void {
    console.log(item);
    this.titleChartTable = item.fund;
    this.generals.amount = item.amount;
    this.generals.totalCost = item.totalCost;
    this.generals.value = item.value;
    this.generals.percent = item.percent;
    this.highlight = item.fund;
    this.highlightserie = item.serie;
  }

  public selectSerie(item):void {
    console.log(item);
    this.router.navigateByUrl('portal-clientes/transaccion/'+item.fund+'/'+item.serie);
  }

  initializeChart(canvas) {
    this.globalChart
      ? this.globalChart.destroy()
      : console.log('GlobalChart null');
    var myChart = new Chart(canvas, {
      type: 'doughnut',
      data: this.pieChartData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
            },
          },
        },
        animation: {
          onComplete: function () {
            console.log('chart ready');
          },
        },
      },
    });

    this.globalChart = myChart;
  }

  private getLastUpdate(lastUpdate) {
    let data = new Date(lastUpdate);
    let day = ('0' + data.getDate().toString()).slice(-2);
    let month = ('0' + (data.getMonth() + 1)).slice(-2);
    let year = data.getFullYear().toString();

    let minutes = ('0' + data.getMinutes().toString()).slice(-2);
    let hours = ('0' + data.getHours().toString()).slice(-2);

    return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
  }

  async printPDF() {
    const empresa = this.bussines.name;
    const fecha = this.formatDate(this.startDate);
    this.isDownloading = true;
    const id = this.idContract;

    //const chartImage = this.globalChart.toBase64Image()
    const chartImage = await ExportService.convertHTMLtoImage('positionChart');
    const imgH = await ExportService.getImageFromLocal(
      '/assets/images/logo.png'
    );
    //var graphTableDiv = await ExportService.convertHTMLtoImage('graphTableDiv');
    //var colDivB = await ExportService.convertHTMLtoImage('colDivB');

    const date = this.formatDateTime(new Date());

    const pdfTGralHeaders = [
      { text: 'Monto', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Costo Total', bold: true, fontSize: 8, alignment: 'center' },
      {
        text: 'Plus/Minus Valía',
        bold: true,
        fontSize: 8,
        alignment: 'center',
      },
      { text: 'Porcentaje', bold: true, fontSize: 8, alignment: 'center' },
    ];
    let rowsGrals: [any[]] = [pdfTGralHeaders];
    rowsGrals.push([
      {
        text: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(parseFloat(this.generals.amount.toString().toString())),
        fontSize: 8,
        alignment: 'right',
      },
      {
        text: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(parseFloat(this.generals.totalCost.toString().toString())),
        fontSize: 8,
        alignment: 'right',
      },
      {
        text: new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(parseFloat(this.generals.value.toString())),
        fontSize: 8,
        alignment: 'right',
      },
      {
        text: this.generals.percent.toString() + '%',
        fontSize: 8,
        alignment: 'right',
      },
    ]);

    const pdfTHeaders = [
      { text: 'Fondo', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Serie', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Títulos', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Monto', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Precio', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Costo Unitario', bold: true, fontSize: 8, alignment: 'center' },
      { text: 'Costo Total', bold: true, fontSize: 8, alignment: 'center' },
      {
        text: 'Plus/Minus Valía',
        bold: true,
        fontSize: 8,
        alignment: 'center',
      },
      { text: 'Porcentaje', bold: true, fontSize: 8, alignment: 'center' },
    ];

    let rows: [any[]] = [pdfTHeaders];

    for (let row of this.data) {
      let r = [
        { text: row.fund.toString(), fontSize: 8, alignment: 'left' },
        { text: row.serie.toString(), fontSize: 8, alignment: 'left' },
        { text: row.titles.toString(), fontSize: 8, alignment: 'left' },
        {
          text: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(parseFloat(row.amount.toString().toString())),
          fontSize: 8,
          alignment: 'right',
        },
        {
          text: '$' + row.priceFormartZeros.toString(),
          fontSize: 8,
          alignment: 'right',
        },
        {
          text: '$' + row.unitCostFormartZeros.toString(),
          fontSize: 8,
          alignment: 'right',
        },
        {
          text: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(parseFloat(row.totalCost.toString().toString())),
          fontSize: 8,
          alignment: 'right',
        },
        {
          text: new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(parseFloat(row.value.toString())),
          fontSize: 8,
          alignment: 'right',
        },
        { text: row.percent.toString() + '%', fontSize: 8, alignment: 'right' },
      ];
      rows.push(r);
    }

    var docDefinition = {
      pageOrientation: 'landscape',
      pageMargins: [20, 150, 10, 30],
      header: function (currentPage, pageCount) {
        return [
          { image: imgH, width: 150, margin: 20 },
          {
            text: 'Contrato ' + id + ' | "' + empresa + '"',
            margin: [20, 0, 0, 10],
            bold: true,
          },
          { text: 'Fecha ' + fecha, margin: [20, 0, 0, 10] },
        ];
      },
      footer: function (currentPage, pageCount) {
        return [
          {
            columns: [
              {
                text: 'Fecha y hora de descarga: ' + date,
                alignment: 'left',
                width: 'auto',
                margin: [20, 0, 0, 0],
              },
              {
                text: 'Página ' + currentPage.toString() + ' de ' + pageCount,
                alignment: 'right',
                margin: [0, 0, 20, 0],
              },
            ],
          },
        ];
      },
      content: [
        {
          text: 'Posición',
          margin: [0, 0, 0, 10],
          fontSize: 18,
          color: '#002FA7',
        },
        {
          text: 'Distribución del portafolio',
          margin: [20, 10, 0, 10],
          bold: true,
        },
        {
          columns: [
            { image: chartImage, width: 250, margin: [20, 0, 0, 0] },
            //{ image: graphTableDiv, width: 350, margin: [100, 100, 0, 0] },
          ],
        },
        {
          layout: 'noBorders',
          pageBreak: 'after',
          table: {
            headerRows: 1,
            widths: ['auto', 'auto', 'auto', 'auto'],
            body: rowsGrals,
          },
        },
        {
          text:
            'Detalles de posición del portafolio, última actualización ' +
            this.lastUpdate,
          margin: [20, 10, 0, 10],
          bold: true,
        },
        {
          layout: 'noBorders',
          table: {
            headerRows: 1,
            widths: [
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
              'auto',
            ],
            body: rows,
          },
        },
      ],
      styles: {
        header: {
          background: 'red',
          lineHeight: 3,
        },
      },
    };

    let rowsExcelT1: [any[]] = [
      ['Monto', 'Costo Total', 'Plus/Minus Valía', 'Porcentaje'],
    ];

    let r = [
      { t: 'n', v: this.generals.amount },
      { t: 'n', v: this.generals.totalCost },
      { t: 'n', v: this.generals.value },
      { t: 'n', v: this.generals.percent },
    ];

    rowsExcelT1.push(r);

    let rowsExcelT2: [any[]] = [
      [
        'Fondo',
        'Serie',
        'Títulos',
        'Monto',
        'Precio',
        'Costo Unitario',
        'Costo Total',
        'Plus/Minus Valía',
        'Porcentaje',
      ],
    ];

    for (let row of this.data) {
      let r = [
        row.fund.toString(),
        row.serie.toString(),
        row.titles.toString(),
        { t: 'n', v: row.amount },
        { t: 'n', v: row.priceFormartZeros },
        { t: 'n', v: row.unitCostFormartZeros },
        { t: 'n', v: row.totalCost },
        { t: 'n', v: row.value },
        { t: 'n', v: row.percent.toString() },
      ];

      rowsExcelT2.push(r);
    }

    const tables = [
      { rows: rowsExcelT1, name: 'Posicion Generales' },
      { rows: rowsExcelT2, name: 'Posicion Detalle' },
    ];
    await ExportService.exportToExcel(tables, 'posicion.xlsx');

    ExportService.exportToPDF(docDefinition, false);

    this.isDownloading = false;
  }

  private formatDateTime(date) {
    let dateFormat = new Date(date);

    let day = ('0' + dateFormat.getDate().toString()).slice(-2);
    let month = ('0' + (dateFormat.getMonth() + 1).toString()).slice(-2);
    let year = dateFormat.getFullYear().toString().toString();
    let hour = ('0' + dateFormat.getHours().toString()).slice(-2);
    let minutes = ('0' + dateFormat.getMinutes().toString()).slice(-2);
    let seconds = ('0' + dateFormat.getSeconds().toString()).slice(-2);

    return (
      day +
      '/' +
      month +
      '/' +
      year +
      ' ' +
      hour +
      ':' +
      minutes +
      ':' +
      seconds
    );
  }

  private formatDate(date) {
    let dateFormat = new Date(date);

    let day = ('0' + dateFormat.getUTCDate().toString()).slice(-2);
    let mesTmp = dateFormat.getUTCMonth();
    let month = ('0' + (mesTmp + 1).toString()).slice(-2);
    let year = dateFormat.getFullYear().toString().toString();

    return day + '/' + month + '/' + year;
  }

  private formatLabelChartTooltip(value) {
    let formatNumber = Number(value.parsed);
    return (
      '$' +
      formatNumber.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  }
}
