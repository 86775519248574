<app-breadcum [data]="dataBreadcum"></app-breadcum>
<app-contracts
  [title]="'Posición'"
  (valueResponse)="getContactData($event)"></app-contracts>

    <div class="row mb-4 mt-4 ms-4 me-4">
      <!-- Card Chart -->
      <div id="colDivA" class="col-xl-4 col-lg-12 mb-5 ps-0 pe-0 pe-lg-2">
        <div class="contt">
          <div class="chart-title mb-3">
            <span class="card-titles">Porcentaje de acciónes en el portafolio</span>
          </div>
          <div class="card">
            <ng-container *ngIf="showChart">
              <div class="d-flex justify-content-center">
                <div class="chart" style="height: 260px">
                  <canvas
                    id="positionChart"
                    baseChart
                    [data]="pieChartData"
                    [type]="'doughnut'"
                    [options]="pieChartOptions">
                  </canvas>
                </div>
              </div>
            </ng-container>
      
            <div id="graphTableDiv" class="d-flex-column">
              <span class="card-titles">{{ titleChartTable }}</span>
              <div class="scroll-table">
                <table id="graphTable" class="table table-borderless">
                  <thead>
                    <tr>
                      <th>Monto</th>
                      <th>Costo total</th>
                      <th>Plus / Minusvalía</th>
                      <th>Porcentaje</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="data.length !== 0">
                      <td>
                        <div class="d-flex flex-row justify-content-end">
                          <div>$</div>
                          <div>
                            {{ generals.amount.toString() | number : '1.2-2' }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex flex-row justify-content-end">
                          <div>$</div>
                          <div>
                            {{ generals.totalCost.toString() | number : '1.2-2' }}
                          </div>
                        </div>
                      </td>
                      <td
                        [ngClass]="{
                          ' red': generals.value < 0,
                          green: generals.value > 0
                        }">
                        <div class="d-flex flex-row justify-content-end">
                          <div>$</div>
                          <div>
                            {{ generals.value.toString() | number : '1.2-2' }}
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        {{ generals.percent.toString() | number : '1.2-2' }} %
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="withoutData" *ngIf="data.length === 0">
                <span>No hay datos disponibles </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="colDivB" class="col-xl-8 col-lg-12 mt-xl-0 mb-100 ps-0 pe-0 ps-lg-2">
        <div class="contt mb-3">
          <div class="d-flex justify-content-between mb-3">
            <div>
              <span class="card-titles">Detalles de posición del portafolio </span
              ><br />
              <span class="card-subtitle">Última actualización {{ lastUpdate }}</span>
            </div>
            <div class="d-flex justify-content-end desktop">
              <button
                *ngIf="!isDownloading"
                class="btn-alternative"
                (click)="printPDF()">
                <object class="me-2" data="/assets/icons/i-download-blue.svg" type=""></object>
                <span>Exportar</span>
              </button>
            </div>
          </div>
          <div class="d-flex justify-content-start mobil">
            <button
              *ngIf="!isDownloading"
              class="btn-alternative"
              (click)="printPDF()">
              <span>Exportar</span>
              <object class="me-2" data="/assets/icons/i-download-blue.svg" type=""></object>
            </button>
          </div>
      
          <div id="mainTable" class="card" style="overflow: auto">
            <table id="positionTable" class="table table-borderless pointerRow">
              <thead>
                <tr>
                  <th>Fondo</th>
                  <th>Serie</th>
                  <th>Títulos</th>
                  <th>Monto</th>
                  <th>Precio</th>
                  <th>Costo unitario</th>
                  <th>Costo total</th>
                  <th>Plus / Minusvalía</th>
                  <th>Porcentaje</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of data">
                  <tr
                    (click)="showSerie(item)"  
                    class="tooltipp" [ngClass]="{ highlightRow: highlight === item.fund && highlightserie === item.serie }"
                    >
                    
                    <td *ngIf="permissionLink" (dblclick)="selectSerie(item)">
                      <span class="tooltiptextt">Al acceder a este enlace, serás redirigido fuera de estapágina</span>
                        {{ item.fund }}
                    </td>
                    <td *ngIf="!permissionLink">
                      <span class="tooltiptextt">Al acceder a este enlace, serás redirigido fuera de estapágina</span>
                        {{ item.fund }}
                    </td>
                    <td>{{ item.serie }}</td>
                    <td>{{ item.titles.toString() | number : '1.0-0' }}</td>
                    <td>
                      <div class="d-flex flex-row justify-content-end">
                        <div>$&nbsp;</div>
                        <div>
                          {{ item.amount.toString() | number : '1.2-2' }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-row justify-content-end">
                        <div>$&nbsp;</div>
                        <div>
                          {{ item.priceFormartZeros.toString() }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-row justify-content-end">
                        <div>$&nbsp;</div>
                        <div>
                          {{ item.unitCostFormartZeros.toString() }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex flex-row justify-content-end">
                        <div>$&nbsp;</div>
                        <div>
                          {{ item.totalCost.toString() | number : '1.2-2' }}
                        </div>
                      </div>
                    </td>
                    <td class="text-end">
                      <div [ngClass]="item.valueNumber > 0 ? 'green' : 'red'">
                        $&nbsp;{{ item.value.toString() | number : '1.2-2' }}
                      </div>
                    </td>
                    <td class="text-end">
                      {{ item.percent.toString() | number : '1.2-2' }}&nbsp;%
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <div class="withoutData" *ngIf="data.length === 0">
              <span>No hay datos disponibles </span>
            </div>
          </div>
        </div>
      </div>
    </div>
