import { Component, Input, OnInit } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';

@Component({
  selector: 'app-breadcum',
  templateUrl: './breadcum.component.html',
  styleUrls: ['./breadcum.component.scss']
})
export class BreadcumComponent extends BaseService implements OnInit {

  @Input() data:any = [];

  breadcum:string[];
  title:string;
  subtitle:string;
  icon:boolean;
  path:string;


  ngOnInit(): void {
    this.icon = this.data[0].icon;
    this.title = this.data[0].title;
    this.subtitle = this.data[0].subTitle;
    this.breadcum = this.data[0].breadcum;
    this.path = this.data[0].path;
    
  }
  redirecTo(){
    
    window.open(this.path);

  }

}
