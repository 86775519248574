<app-breadcum [data]="dataBreadcum"></app-breadcum>
<app-contracts
  (valueResponse)="getContactData($event)"
  [title]="'Flujos'"></app-contracts>

  <div class="contt me-4 ms-4">
    <div id="flowDiv" class="row mt-4">
      <div class="col-xl-12 col-lg-12 col-12 mb-3">
        <div class="d-flex flex-row mb-3">
          <div class="col-12 col-ms-12 col-md-6 chart-title d-flex align-items-center">
            <div>
              <span class="card-top-out-title table">Flujos</span>
            </div>
          </div>
  
          <div class="col-12 col-ms-12 col-md-6 d-flex justify-content-end">
            <button
              *ngIf="!isDownloading"
              class="btn-alternative"
              (click)="printPDF()">
              <!-- <span class="material-symbols-outlined icon me-2"> download </span> -->
              <object class="me-2" data="/assets/icons/i-download-blue.svg" type=""></object>
              <span>Exportar</span>
            </button>
          </div>
        </div>

        <div class="card ps-0 pe-0 mb-100">
          <div class="scroll-table">
            <table id="flowTable" class="table table-borderless table-flujo">
              <thead>
                <tr class="borden-bottom-table">
                  <th></th>
                  <th>Hoy</th>
                  <th>24 hrs</th>
                  <th>48 hrs</th>
                  <th>72 hrs</th>
                  <th>TOTAL</th>
                </tr>
                <!-- <tr class="borden-bottom-table"> -->
                <ng-container *ngIf="available.length > 0">
                  <tr>
                    <th *ngFor="let item of available">{{ item }}</th>
                    <th class="borden-left-table"></th>
                  </tr>
                </ng-container>
              </thead>
              <tbody>
                <tr *ngFor="let item of data">
                  <td>{{ item.concept }}</td>
                  <td>
                    <div class="d-flex flex-row justify-content-start">
                      <div>$</div>
                      <div>
                        {{ item.today.toString() | number : '1.2-2' }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-row justify-content-start">
                      <div>$</div>
                      <div>
                        {{ item.twentyfour.toString() | number : '1.2-2' }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-row justify-content-start">
                      <div>$</div>
                      <div>
                        {{ item.fourtyeight.toString() | number : '1.2-2' }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-row justify-content-start">
                      <div>$</div>
                      <div>
                        {{ item.seventytwo.toString() | number : '1.2-2' }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex flex-row justify-content-start oficial-blue">
                      <div>$</div>
                      <div>
                        {{ item.total.toString() | number : '1.2-2' }}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="withoutData" *ngIf="data.length === 0">
            <span>No hay datos disponibles </span>
          </div>
        </div>
      </div>
  </div>
</div>
