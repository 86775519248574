<form [formGroup]="myForm" class="mt-4">
  <div>
    <p>
      Se entenderá como Beneficiarios Finales a la(s) persona(s) natural(es) que
      finalmente posee, directa on indirectamente, a través de sociedades u
      otros mecanismos, una participación igual o mayor al 10% del capital o de
      los derechos a voto de una persona jurídica determinada.
    </p>
    <p>
      Así mismo, se entenderá como Beneficiario Final a la(s) persona(s)
      natural(es) que, sin prejuicio de poseer directa o indirectamente una
      participación inferior al 10% del capital o de los derechos a voto de una
      persona jurídica, a través de sociedades u otros mecanismos, ejerce el
      control efectivo de la persona o estructura jurídica.
    </p>
  </div>
  <p>2.1 BENEFICIARIOS FINALES</p>
  <div class="custom_row">
    <p>
      Identifique las personas naturales que tienen una participación en la
      persona o estructura jurídica declarante igual o mayor al 10%
    </p>
  </div>
  <div formArrayName="beneficiaries">
    <div
      class="custom_row"
      *ngFor="
        let item of myForm.controls['beneficiaries']['controls'];
        let i = index
      "
      [formGroupName]="i">
      <div class="col-1 d-flex flex-column">
        <label class="weight-500" for="cdi_id">CNI/ID</label>
        <input type="text" class="input_primary" formControlName="cdi_id" />
      </div>
      <div class="col-2 d-flex flex-column">
        <label class="weight-500" for="name">Nombre completo</label>
        <input type="text" class="input_primary" formControlName="name" />
      </div>
      <div class="col-2 d-flex flex-column">
        <label class="weight-500" for="address">Domicilio</label>
        <input type="text" class="input_primary" formControlName="address" />
      </div>
      <div class="col-1 d-flex flex-column">
        <label class="weight-500" for="nacionality">Nacionalidad</label>
        <input
          type="text"
          class="input_primary"
          formControlName="nacionality" />
      </div>
      <div class="col-1 d-flex flex-column">
        <label class="weight-500" for="country"> País</label>
        <input type="text" class="input_primary" formControlName="country" />
      </div>
      <div class="col-1 d-flex flex-column">
        <label class="weight-500" for="us_person"> US Person</label>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault{{ i }}"
            value="Si"
            id="flexRadioDefault1"
            formControlName="us_person" />
          <label class="form-check-label" for="flexRadioDefault1"> Si </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault{{ i }}"
            value="No"
            id="flexRadioDefault2"
            formControlName="us_person" />
          <label class="form-check-label" for="flexRadioDefault2"> No </label>
        </div>
      </div>
      <div class="col-2 d-flex flex-column">
        <label class="weight-500" for="tin"> TIN</label>
        <input type="text" class="input_primary" formControlName="tin" />
      </div>
      <div class="col-2 d-flex flex-column">
        <label class="weight-500" for="percentage">
          % Part. en la PJ declarante</label
        >
        <div class="d-flex">
          <input
            type="text"
            class="input_primary"
            formControlName="percentage"
            style="width: 70%" />
          <div
            class="d-flex justify-content-end align-items-center"
            style="width: 20%">
            <span
              class="material-symbols-outlined second-blue pointer"
              (click)="deleteBeneficiaries(i)">
              delete
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="custom_row">
    <div class="d-flex second-blue">
      <span class="pointer material-symbols-outlined pr-3"> add_circle </span>
      <p class="pointer ms-2" (click)="addBeneficiaries()">
        Agregar beneficiarios
      </p>
    </div>
  </div>
  <p>2.2 Control Efectivo</p>
  <div class="custom_row">
    <p>
      Identifique las personas naturales que sin prejuicio de poseer directa o
      indirectamente, una participación inferior al 10%, ejerce el control
      efectivo de la persona o estructura jurídica declarante.
    </p>
  </div>
  <div formArrayName="effective_control">
    <div
      *ngFor="
        let item of myForm.controls['effective_control']['controls'];
        let i = index
      "
      [formGroupName]="i">
      <div class="custom_row">
        <div class="col-1 d-flex flex-column">
          <label class="weight-500" for="cdi_id">CNI/ID</label>
          <input type="text" class="input_primary" formControlName="cdi_id" />
        </div>
        <div class="col-2 d-flex flex-column">
          <label class="weight-500" for="name">Nombre completo</label>
          <input type="text" class="input_primary" formControlName="name" />
        </div>
        <div class="col-2 d-flex flex-column">
          <label class="weight-500" for="address">Domicilio</label>
          <input type="text" class="input_primary" formControlName="address" />
        </div>
        <div class="col-1 d-flex flex-column">
          <label class="weight-500" for="nacionality">Nacionalidad</label>
          <input
            type="text"
            class="input_primary"
            formControlName="nacionality" />
        </div>
        <div class="col-1 d-flex flex-column">
          <label class="weight-500" for="country"> País</label>
          <input type="text" class="input_primary" formControlName="country" />
        </div>
        <div class="col-1 d-flex flex-column">
          <label class="weight-500" for="us_person"> US Person</label>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="UsRadio{{ i }}"
              value="Si"
              id="UsRadio1"
              formControlName="us_person" />
            <label class="form-check-label" for="UsRadio1"> Si </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="UsRadio{{ i }}"
              value="No"
              id="UsRadio2"
              formControlName="us_person" />
            <label class="form-check-label" for="UsRadio2"> No </label>
          </div>
        </div>
        <div class="col-2 d-flex flex-column">
          <label class="weight-500" for="tin"> TIN</label>
          <input type="text" class="input_primary" formControlName="tin" />
        </div>
        <div class="col-2 d-flex flex-column">
          <label class="weight-500" for="percentage">
            % Part. en la PJ declarante</label
          >
          <div class="d-flex">
            <input
              type="text"
              class="input_primary"
              formControlName="percentage"
              style="width: 70%" />
            <div
              class="d-flex justify-content-end align-items-center"
              style="width: 20%">
              <span
                class="material-symbols-outlined second-blue pointer"
                (click)="deleteEffective_control(i)">
                delete
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="custom_row">
    <div class="d-flex second-blue">
      <span class="pointer material-symbols-outlined pr-3"> add_circle </span>
      <p class="pointer ms-2" (click)="addEffective_control()">
        Agregar beneficiarios
      </p>
    </div>
  </div>
</form>
