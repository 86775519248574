import { Component, OnInit } from '@angular/core';
import { TitleContainerService } from './title-container.service';

@Component({
  selector: 'app-title-container',
  templateUrl: './title-container.component.html',
  styleUrls: ['./title-container.component.scss'],
})
export class TitleContainerComponent implements OnInit {
  /**
   * @property {string} Show or hide succes icon
   * @public
   */
  show: boolean;
  /**
   * @property {string[]} List breadcum elements
   * @public
   */
  breadcum: string;
  /**
   * @property {string} Title text
   * @public
   */
  title: string;
  /**
   * @property {string} SubTitle text
   * @public
   */
  subTitle: string;

  constructor(private titleContainerService: TitleContainerService) {}

  ngOnInit() {
    this.titleContainerService.currentMessage.subscribe(response => {
      this.show = response.show;
      this.breadcum = response.breadcum;
      this.title = response.title;
      this.subTitle = response.subTitle;
    });
  }
}
