/**
 * @author       Eduardo Leonardo Torres Guevara <eduardo.tg@ix.agency>
 * @copyright    2023 IX Agency.
 * @lastmodifiedDate 14.02.2023 13:00
 */
import { Component, OnInit } from '@angular/core';
import { Idle } from 'idlejs';
import { SessionService } from './util/session/session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  public timeout:any;

  constructor(public session: SessionService){}
  title = 'compass-app-angular';

  ngOnInit(): void {
  }


  

  
}
