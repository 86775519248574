<div class="row">
  <div class="col-xl-4 col-md-5 col-sm-12 mb-3">
    <ng-container *ngIf="!showForm">
      <div class="card cardBG p-3">
        <div class="change-password-header d-flex name-content">
          <div class="d-flex pb-1">
            <img src="assets/icons/i-user-bold-gray.svg" alt="" />
            <span>{{ userName }}.</span>
          </div>
        </div>
        <div class="container-body-first-card">
          <div
            class="d-flex justify-content-between w-100"
            [ngClass]="{ ' mb-5': showForm }">
            <p class="">Cambiar contraseña</p>
            <span
              class="material-symbols-outlined pointer pencil"
              (click)="sendCode()">
              edit
            </span>
          </div>
          <span class="label-email"
            >Al dar click se enviará un código para que se haga el cambio de
            contraseña.</span
          >
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showForm">
      <div class="card cardBG p-3 mb-100">
        <div class="change-password">
          <form [formGroup]="myForm" (ngSubmit)="onSubmitCode(myForm)">
            <label class="title mb-4">Ingresar Código</label><br />
            <span class="" for="mail">Código enviado al correo</span>
            <input
              type="text"
              formControlName="code"
              class="form-control code-input mb-4" />
            <span for="Contraseña" style="margin-top: 12px">Contraseña</span>
            <input
              type="password"
              id="password"
              formControlName="password"
              placeholder="Contraseña segura"
              class="form-control mb-2" />
            <span
              class="material-symbols-outlined buttton-eye"
              (click)="showPass()">
              {{ eyeIcon }}
            </span>
            <span for="new" style="margin-top: 15px">Confirmar contraseña</span>
            <input
              type="password"
              id="passwordConfirm"
              formControlName="repeatePassword"
              placeholder="Contraseña segura"
              class="form-control mb-2" />
            <span
              class="material-symbols-outlined buttton-eye"
              (click)="showPassConfirm()">
              {{ eyeIconConfirm }}
            </span>
            <div class="password-requirements">
              <span> Contraseña debe contener al menos: </span>
              <ul class="mb-5">
                <li>8 caracteres</li>
                <li>1 número</li>
                <li>1 mayúscula</li>
                <li>1 minúscula</li>
                <li>1 caracter especial</li>
              </ul>
            </div>

            <div class="d-flex justify-content-between">
              <button
                type="button"
                class="btn btn-alternative w-45 one-line"
                (click)="sendCode()">
                Reenviar código
              </button>
              <button
                type="submit"
                class="btn btn-primary w-45 ml-3"
                [disabled]="myForm.invalid">
                Continuar
              </button>
            </div>
            <div class="d-flex justify-content-center mt-3">
              <span class="material-symbols-outlined arrow arrow">
                chevron_left
              </span>
              <a (click)="back()" routerLinkActive="active"> Volver </a>
            </div>
          </form>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-xl-4 col-md-5 col-sm-12 mb-3" *ngIf="wachConfig">
    <div class="card cardBG p-3">
        <div class="change-password-header d-flex name-content">
          <div class="d-flex pb-1">
            <span>Configuracion</span>
          </div>
        </div>
        <div class="container-body-first-card">
          <div
            class="d-flex justify-content-between w-100" *ngIf="!edittime"
            [ngClass]="{ ' mb-5': showForm }">
            <p class="">Cambiar cierre de sesión</p>
            <span
              class="material-symbols-outlined pointer pencil"
              (click)="edit()">
              edit
            </span>
          </div>
          <div class="d-flex justify-content-between w-100 mb-5" *ngIf="edittime">
            <input type="text" name="time" class="form-control" placeholder="Nuevo tiempo" [(ngModel)]="timechange">
            <button type="button" class="btn btn-success ms-3" (click)="guardar()">Guardar</button>
          </div>
          <span class="label-email"
            >El tiempo se maneja en minutos.</span
          >
        </div>
      </div>
  </div>
</div>
