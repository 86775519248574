import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends BaseService implements OnInit {
  public user = '';
  private secretKey = 'secretodeamor';

  ngOnInit(): void {
    let email = this.session.getEmail();

    this.getAll('/contract?email=' + email).subscribe(async response => {
      
      if (response.success !== false) {
        this.user = response.data.user;
        localStorage.setItem('userName', response.data.user);
        localStorage.setItem('contract',response.data.companies[0].contracts[0])
      }
    });
  }

  sendDataToSideBar() {
    this.dataService.sendDataFromNavBar({
      message: 'Abrir menu',
    });
  }  
}
