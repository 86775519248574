import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class DataService {
  private dataFromSideBar = new Subject<any>();
  private dataFromNavBar = new Subject<any>();

  sendDataFromSideBar(data: any) {
    this.dataFromSideBar.next(data);
  }

  sendDataFromNavBar(data: any) {
    this.dataFromNavBar.next(data);
  }

  getDataFromSideBar() {
    return this.dataFromSideBar.asObservable();
  }

  getDataFromNavBar() {
    return this.dataFromNavBar.asObservable();
  }

  encriptarArray(array:any,secretKey:string){
    // Convierte el array a una cadena JSON
    const jsonString = JSON.stringify(array);
    // Encripta la cadena JSON usando AES y la clave secreta
    const encryptedData = CryptoJS.AES.encrypt(jsonString, secretKey).toString();
    return encryptedData;
  }
  desencriptarArray(encryptedData:string,secretKey:string){
    // Descifra la cadena encriptada
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, secretKey).toString(CryptoJS.enc.Utf8);
   // Convierte la cadena JSON descifrada de nuevo a un array
   const decryptedArray = JSON.parse(decryptedData);
   return decryptedArray;
 }

}
