<!-- NavBar desktop y tablet -->
<nav class="navbar navbar-expand-lg header-shadow w-100 bigger">
  <div class="w-100">
    <div class="d-flex d-row ">
      <div class="col-xl-9 col-sm-6">
        <img
          src="assets/images/logo.png"
          alt="Logo"
          width="auto"
          class="d-inline-block align-text-top logo"
          style="max-height: 40px; width: 140px"
          alt="Compass logo" />
      </div>

      <div class="col-xl-3 col-sm-6">
        <div
          class="d-flex justify-content-end align-items-center h-100 position-relative d-inline-block">
          <div class="user">
            <label for="user">{{ user | titlecase }}</label>
          </div>
          <div class="user-options">
            <img
              src="../../../assets/icons/i-arrow-down.svg"
              alt="arrow down" />
          </div>
          <app-user-options class="options"></app-user-options>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- NavBar Mobil -->
<nav class="navbar navbar-expand-lg header-shadow w-100 phone">
  <div class="w-100">
    <div class="d-flex d-row  justify-content-between phone_padding">
      <div class="d-flex align-items-center">
        <img
          src="assets/images/hamburguer.png"
          alt="Logo"
          width="auto"
          class="d-inline-block align-text-top logo"
          style="max-height: 35px"
          alt="Compass logo" 
          (click)="sendDataToSideBar()"/>
      </div>

      <div class="flex align-items-center col-xl-9 col-sm-6">
        <img
          src="assets/images/logo.png"
          alt="Logo"
          width="auto"
          class="d-inline-block align-text-top logo"
          style="max-height: 35px; width: 115px"
          alt="Compass logo" />
      </div>

      <div class="col-xl-3 col-sm-6">
        <div
          class="d-flex justify-content-end align-items-center h-100 position-relative d-inline-block">
          <div class="user">
            <label for="user">{{ user | titlecase }}</label>
          </div>
          <div class="user-options">
            <img
              src="../../../assets/icons/i-arrow-down.svg"
              alt="arrow down" />
          </div>
          <app-user-options class="options"></app-user-options>
        </div>
      </div>
    </div>
  </div>
</nav>

<!-- Menu lateral en mobil -->
<!-- <aside class="sidebar_phone phone" *ngIf="!menuOpen">
  <nav>
    <ul *ngIf="menu_items.length > 0" class="nav_items ju">
      <li>
        <span
          class="material-symbols-outlined pointer close"
          (click)="toggleMenu()">
          close
        </span>
      </li>
      <li
        *ngFor="let item of menu_items"
        class="tooltipp"
        [ngClass]="{ selected: item.isSelected, tooltipp: item.tooltip }"
        (click)="routerToPath(item.route, item)">
        <a class="h-100">
          <div class="d-flex">
            <img class="img_menu" src="{{ item.icon }}" alt="{{ item.name }}" />
            <span class="nav-text title_menu"> {{ item.name }} </span>
          </div>
        </a>
      </li>
    </ul>
  </nav>
</aside>
 -->