import { Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';

@Component({
  selector: 'app-caracteristicas',
  templateUrl: './caracteristicas.component.html',
  styleUrls: ['./caracteristicas.component.scss']
})
export class CaracteristicasComponent extends BaseService implements OnInit {

  ngOnInit(): void {
    this.loaderTransparent.show(true);
    this.dataBreadcum = [{
      breadcum: ['Home', 'Portal de clientes','Caracteristicas fondos'],
      title: 'Características fondos',
      subTitle : '',
      icon:false
     }];
     setTimeout(() => {
      this.loaderTransparent.show(false);
    }, 1500);
  }

}
