<ng-container *ngIf="showModal">
  <!-- The Modal -->
  <div id="myModal" class="modal">
    <!-- Modal content -->
    <div class="modal-content">
      <!--       <div class="w-100 d-flex">boton de cerrar
        <span class="close">&times;</span>
      </div> -->

      <p>{{ body }}</p>
      <div class="button-content">
        <div class="col-xl-2 col-lg-2 col-md-3 col-4">
          <button class="btn btn-alternative w-100" (click)="close()">
            Cerrar
          </button>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-4">
          <button class="btn btn-primary w-100 h-100" (click)="aceptar()">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
