import { HttpClientModule } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AuthModule } from '@auth0/auth0-angular';

import { APP_ROUTES } from './app-routing/app.routing';
import { AppComponent } from './app.component';
import { BeneficiariosModule } from './beneficiarios/beneficiarios.module';
import { LoaderComponent } from './common/loader/loader.component';
import { ModalComponent } from './common/modal/modal.component';
import { LoaderService } from './common/loader/loader.service';
import { ModalService } from './common/modal/modal.service';
import { LoaderTransparentComponent } from './common/loaderTransparent/loaderTransparent.component';
import { LoaderTransparentService } from './common/loaderTransparent/loaderTransparent.service';
import { NotificationComponent } from './common/notification/notification.component';
import { NotificationService } from './common/notification/notification.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { PortalClientesModule } from './portal-clientes/portal-clientes.module';
import { LoginTwoFactorComponent } from './public/login-two-factor/login-two-factor.component';
import { LoginComponent } from './public/login/login.component';
import { NotFoundComponent } from './public/not-found/not-found.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { SingUpComponent } from './public/sing-up/sing-up.component';
import { RequestService } from './request/request.service';
import { AccessGuard } from './util/canActive/access.guard';
import { ExportService } from './util/exportFiles/export.service';
import { SessionService } from './util/session/session.service';
import { BreadcumComponent } from './common/breadcum/breadcum.component';

export let InjectorInstance: Injector;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotFoundComponent,
    SingUpComponent,
    ResetPasswordComponent,
    NotificationComponent,
    LoaderComponent,
    LoaderTransparentComponent,
    LoginTwoFactorComponent,
    ModalComponent,
  ],
  providers: [
    RequestService,
    AccessGuard,
    SessionService,
    NotificationService,
    LoaderService,
    LoaderTransparentService,
    ExportService,
    ModalService,
  ],
  bootstrap: [AppComponent],
  exports: [],
  imports: [
    BrowserModule,
    PortalClientesModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(APP_ROUTES),
    BeneficiariosModule,
    DashboardModule,
    AuthModule.forRoot({
      domain: 'login.cgcompass.com',
      clientId: 'WafyDF9S6DVHKcRpg0cIf0qODg1l5w14',
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    }),
  ],
})
export class AppModule {
  constructor(private injector: Injector) {
    InjectorInstance = this.injector;
  }
}
