import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/base/base.service';

@Component({
  selector: 'app-form-legal-person',
  templateUrl: './form-legal-person.component.html',
  styleUrls: ['./form-legal-person.component.scss'],
})
export class FormLegalPersonComponent extends BaseService implements OnInit {
  /*
   * Send form to principal form
   */
  @Output() formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  /**
   * List society types
   */
  public society_types = [
    { name: 'anonima', label: 'Anónima' },
    { name: 'colectiva', label: 'Colectiva' },
    { name: 'comandita', label: 'En comandita' },
    { name: 'limitada', label: 'Limitada' },
    { name: 'eirl', label: 'EIRL' },
  ];

  disableOtherField = false;

  ngOnInit(): void {
    this.myForm = this.fb.group({
      razon_social: ['', Validators.required],
      put_firts: ['', Validators.required],
      domicilio: ['', Validators.required],
      ciudad: ['', Validators.required],
      telefono: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      constitucion: ['', Validators.required],
      representante: ['', Validators.required],
      put_sec: ['', Validators.required],
      society: ['', Validators.required],
      society_other: [{ value: ''}],
      identify_people: this.fb.array([this.initRows()]),
    });

    this.formReady.emit(this.myForm);
  }

  get identify_people() {
    return this.myForm.get('identify_people') as FormArray;
  }

  public isDisabled() {
    console.log(this.myForm.value.society);
    console.log(this.myForm.get('society')!['value']);

    if (this.myForm.value.society !== 'other') {
      this.disableOtherField = false;
    } else this.disableOtherField = true;
  }

  /**
   * addPerson
   * Add person in identify_people array
   */
  public addPerson() {
    this.identify_people.push(this.initRows());
  }

  public removeOtherSociety() {
    this.myForm.value.society_other = '';
    document.getElementById('otro')!['value'] = '';
    //this.isDisabled()
  }

  /**
   * deletePerson
   * Delete row in identify_people array
   */
  public deletePerson(i: number) {
    if (this.identify_people.length > 1) this.identify_people.removeAt(i);
  }

  initRows() {
    return this.fb.group({
      identify: ['', Validators.required],
      position: ['', Validators.required],
      name: ['', Validators.required],
    });
  }
}
