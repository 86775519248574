import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Subscription } from 'rxjs';
import { sidebarMenuOptions } from 'src/app/common/constants/constants';
import { SessionService } from 'src/app/util/session/session.service';

import { DashboardComponent } from '../../dashboard/dashboard.component';
import { ImenuItems } from './../../common/dtos/sidebarItemsInterface';
import { DataService } from './../../common/services/menu.service';
import { ModalService } from './../../common/modal/modal.service';
import { PortalClientesComponent } from 'src/app/portal-clientes/portal-clientes.component';

@Component({
  selector: 'app-alternative-sidebar',
  templateUrl: './alternative-sidebar.component.html',
  styleUrls: ['./alternative-sidebar.component.scss'],
})
export class AlternativeSidebarComponent implements OnInit {
  private subscription: Subscription;
  menuOpen: boolean = false;
  public isOpen = false;
  public showBanner = false;

  constructor(
    private session: SessionService,
    public dashboard: DashboardComponent,
    public router: Router,
    public auth: AuthService,
    private dataService: DataService,
    public modal: ModalService
  ) {}

  public menu_items: any[] = [];
  public message_modal:string = "";
  selectedsubmenu:boolean = false;
  item_selected: any = {};
  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  ngOnInit(): void {

    setTimeout(() => {
      this.item_selected = localStorage.getItem('itemSelected');
    }, 1000);
    this.dashboard.currentPermitions.subscribe((response: any) => {
      sidebarMenuOptions.items.forEach(item => {
        if (response.includes(item.name)) this.menu_items.push(item);
      });

      this.menu_items.forEach(element => {
        if (element.name === this.item_selected ) {
          if (element.itemsubmenu === true) {
            this.selectedsubmenu = true;
          }
        }else{
          element.isSelected = false;
        }
      });
    });
    


    this.dataService.getDataFromNavBar().subscribe(data => {
      this.menuOpen = !this.menuOpen;
    });

    
    this.dataService.getDataFromNavBar().subscribe(data => {
      this.isOpen = !this.isOpen;
      if (this.isOpen === true) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    });

    this.message_modal = "Al hacer click en este link usted será redirigido a otro sitio de Internet que no es administrado por Compass. No ejercemos ningún control sobre el administrador de dicho sitio ni tampoco lo respaldamos como organización, empresa o producto. Si accede al sitio vinculado al enlace, lo hará por su cuenta y riesgo. Compass no ofrece ninguna declaración ni garantía respecto al sitio enlazado incluyendo su contenido, respecto del cual deberá referirse a las políticas y condiciones de uso del sitio web anfitrión.";
  }

  public openMenu(): void {
    let menu = document.getElementById('menu');
    menu!.style.width = '245px';
  }

  public closeMenu(): void {
    let width = window.screen.width;
    if (width <= 992) {
      let menu = document.getElementById('menu');
      menu!.style.width = '0px';
    }
    let menu = document.getElementById('menu');
      menu!.style.width = 'auto';
  }

  routerToPath(path: string, item: any): void {
    switch (path) {
      case '../login':
        this.session.destroy();
        break;
      case 'cg-dynamic-report':
        //this.modal.show(true,this.message_modal,'https://login.cgcompass.com/samlp/WafyDF9S6DVHKcRpg0cIf0qODg1l5w14');
        window.open(
          'https://login.cgcompass.com/samlp/WafyDF9S6DVHKcRpg0cIf0qODg1l5w14'
        );
        break;
      case 'cg-alternatives':
        //this.modal.show(true,this.message_modal,'https://iam.intralinks.com/idp/login/?applicationid=98e3df54-2ef1-48b4-82d4-02b83d273d23&br=bac14941-e126-4cfa-9984-7fe254084cfe&hostname=services.intralinks.com');
        window.open(
          'https://iam.intralinks.com/idp/login/?applicationid=98e3df54-2ef1-48b4-82d4-02b83d273d23&br=bac14941-e126-4cfa-9984-7fe254084cfe&hostname=services.intralinks.com'
        );
        break;
      case 'portal-clientes':
        this.router.navigate([path]);
        break;
      case 'NetX':
        //this.modal.show(true,this.message_modal,'https://www.netxinvestor.com/nxi/welcome?nextPage=https:%2F%2Fwww.netxinvestor.com%2Fnxi%2Foverview');
        window.open(
          'https://www.netxinvestor.com/nxi/welcome?nextPage=https:%2F%2Fwww.netxinvestor.com%2Fnxi%2Foverview'
        );
        break;
      case 'transaccion':
          this.router.navigate(['portal-clientes/' + path]);
          this.menu_items.map(item => {
            if (item.isSelected) item.isSelected = false;
          });
          item.isSelected = true;
        break;
      default:
        this.router.navigate(['dashboard/' + path]);
        this.menu_items.map(item => {
          if (item.isSelected) item.isSelected = false;
        });
        item.isSelected = true;
        break;
    }
    localStorage.setItem('itemSelected',item.name);
  }
}
