import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'

@Component({
  selector: 'app-recursos',
  templateUrl: './recursos.component.html',
  styleUrls: ['./recursos.component.scss']
})
export class RecursosComponent extends BaseService implements OnInit {
  
  
  
  
  public btnActive:boolean = false;//boton de transferir
  public btnimportetotal:boolean = false;
  public saldoEfectivo:string;
  public data_bancos:any = [];
  public dataOperation:any = {};
  public coords:any = {};
  public img:string = '';
  public cashAll:any = [];
  @Input() dataTransferencia:any = [];
  @Input() data:any = [];
  @Input() money:number;//mostrar el total que se acaba de vender
  @Input() contrato:string;
  @Input() operacion:string = '';//el tipo de operacion que mando a llamar, ya sea 'chequera' => transferir a chequera, 'compven' => cuando realizo la compra o venta de una acción
  @Output() dataToParent = new EventEmitter<string>();
  //@Output() datatofinish = neww EventEmitter<string>();

  
  
  async ngOnInit(): Promise<void> {
    this.img = "/assets/images/logo.png";
    this.data_bancos = this.data.data;
    console.log("dataTrasnfgerecni",this.dataTransferencia);
    //this.cashAll = await this.getSaldos(this.contrato);
    if (this.operacion === 'compven') {
      this.money = this.dataTransferencia.importe;
      let operacion:any  = await this.getChequeraContrato(this.contrato,'VTA');
      this.data_bancos = operacion.data;
    }

    this.saldoEfectivo = this.formatearMonto(this.money);
    let res = await this.getIP();
    this.coords.ip = res;
    this.dataOperation.cuenta = '';
    this.dataOperation.monto = 0;
    this.coords.latitud = localStorage.getItem('latitude');
    this.coords.longitud = localStorage.getItem('longitude');
    try {
      this.coords.address = await this.getAdressComplete();
    } catch (error) {
      this.coords = error;
    }
    this.loaderTransparent.show(false);
  }



  selectItem(option:number){

    let chequera = document.getElementById('chequera') as HTMLInputElement;
    let saldo = document.getElementById('saldo') as HTMLInputElement;
    if (option === 1) {
      chequera.checked = true
      this.btnActive = true;
      this.btnimportetotal = true;
    }
    if (option === 2) {
      saldo.checked = true;
      this.btnActive = false;
    }
  }


  selectImporte(option:number){

    let imptotal = document.getElementById('imptotal') as HTMLInputElement;
    let otromonto = document.getElementById('otromonto') as HTMLInputElement;
    let efectivo = document.getElementById('efectivod') as HTMLInputElement;

    if (option === 1) {
      imptotal.checked = true;
      this.btnimportetotal = true;
    }
    if (option === 2) {
      otromonto.checked = true;
      this.btnimportetotal = false;
    }
  }


  returnBack(){
    let retorno :any = {
      opcion :'',
      destino : 'cancelar',
      tipooperacion:this.dataTransferencia.operacion
    }
    this.dataToParent.emit(retorno);
  }

  async AceptarTransaccion(){
    console.log(this.dataOperation);

    if (this.operacion === 'chequera') {
      
      if (this.dataOperation.cuenta === '') {
        this.notification.show('block', '', 'Selecciona una cuenta', '', 'error', '');
        return;
      }
      if (this.dataOperation.amount > Number(this.saldoEfectivo.replace(/[^0-9.]/g, ""))) {
        this.notification.show('block', '', 'No tienes fondos suficientes', '', 'error', '');
        return;
      }
    }
    

    if (this.operacion === 'compven') {

      if (this.btnActive === false) {
        this.dataOperation.monto = this.formatearMonto(this.money);
        this.dataOperation.cuenta = '314';
        this.dataOperation.amount = this.dataOperation.monto;
      }else{
         if (this.dataOperation.cuenta === '') {
            this.notification.show('block', '', 'Selecciona una cuenta', '', 'error', '');
            return;
          }
        if (this.btnimportetotal === false) {
          let efectivo = document.getElementById('efectivod') as HTMLInputElement;
          this.dataOperation.monto = this.formatearMonto(parseFloat(efectivo.value));
        }else{
          this.dataOperation.monto = this.formatearMonto(this.money);
        }
        this.dataOperation.amount = this.dataOperation.monto;
      }
      if (this.dataOperation.amount > Number(this.saldoEfectivo.replace(/[^0-9.]/g, ""))) {

        this.notification.show('block', '', 'No tienes fondos suficientes', '', 'error', '');
        return;
      }    

    }
   

      this.loaderTransparent.show(true);
      console.log(this.btnActive);
      
      if (this.dataTransferencia.operacion === 'vender' && this.btnActive === false) {
        this.dataTransferencia.folio_movimiento = 'agregar_efectivo_disponible';
        this.dataTransferencia.importe = this.dataOperation.monto;
        this.dataTransferencia.op = 'RET';
        this.dataTransferencia.operacion = 'Transferencia';
        this.dataTransferencia.efectivo = this.dataOperation.amount;
        this.dataTransferencia.folioOP = '';
        this.dataTransferencia.foliotraspaso = '';
        this.dataTransferencia.status = 200;
        this.dataTransferencia.fecha_ejecucion = this.dataTransferencia.fecha_ejecucion;
        this.dataTransferencia.fecha_solicitud = this.dataTransferencia.fecha_anticipacion;
        this.dataTransferencia.addefectivo = true;
        this.dataTransferencia.cuentadestino = ''
      }else{
        let respo:any = await this.getGrabarOP();
        if (respo.data[0].status == "200") {
          this.dataTransferencia.folio_movimiento = respo.data[0].folio_movimiento;
          this.dataTransferencia.efectivo = respo.data[0].efectivo;
          this.dataTransferencia.op = 'RET';
          this.dataTransferencia.operacion = 'Transferencia';
          this.dataTransferencia.importe = this.dataOperation.monto;//efectivo ira qui
          this.dataTransferencia.efectivo = this.dataOperation.amount;//efectivo ira qui
          this.dataTransferencia.folioOP = '';
          this.dataTransferencia.foliotraspaso = '';
          this.dataTransferencia.fecha_ejecucion = respo.data[0].fecha_operacion;
          this.dataTransferencia.fecha_solicitud = respo.data[0].fecha_registro;
          this.dataTransferencia.status = 200;
          this.dataTransferencia.addefectivo = false;
          
        }else{
          this.notification.show('block', '', respo.data[0].error, '', 'error_sendcode', '');
          this.loaderTransparent.show(false);
        }
        let cuentades =  this.data_bancos.find(elem => elem.liq == this.dataOperation.cuenta);
        this.dataTransferencia.cuentadestino = cuentades.tarjetatext;
      }
    

      let finish:any = {
        data: this.dataTransferencia,
        destino: 'confirmar',
        operacion: 'transferencia',
      }
      this.generarPDF();
      this.dataToParent.emit(finish);
  }


  async generarPDF(){
    
    const doc = new jsPDF();
    let array: any[] = [];
    let operacion = 'TRANSFERENCIA';
    let listValue = await this.listItems();
    
    const fontPPath = '/LiraSansCompass-Bold.otf'
    const fontPPathNormal = '/LiraSansCompass-Regular.otf'

    doc.addFileToVFS('LiraSansCompass-Regular.otf',fontPPathNormal);
    doc.addFileToVFS('LiraSansCompass-Bold.otf',fontPPath);
    
    doc.addFont('LiraSansCompass-Bold.otf','lirasansBold','normal');
    doc.addFont('LiraSansCompass-Regular.otf','lirasansRegular','normal');
    
    doc.addImage(this.img, "JPEG", 13, 10, 50, 15,'','FAST'),
    doc.setFillColor(51, 180, 255);
    doc.setFont("lirasansBold", "normal", 700);
    doc.setFontSize(16);
    doc.setFont("lirasansRegular", "normal", 700);
    doc.text("COMPROBANTE DE OPERACIÓN - " + operacion, 16, 43);
    const fontList = doc.getFontList();

    const styles = {
      font: 'lirasansRegular',
      fontStyle:'normal',
      fontSize: 13, // Tamaño de fuente para todas las celdas
    };

    autoTable(doc, {
      body: listValue,
      theme:'plain',
      columnStyles: {0 : { cellWidth:80 ,textColor: [30,30,30] }, 1: { cellWidth:80 ,textColor: [77,77,77] }},
      styles: { fontSize: 13},
      startY: 55
    })
    // Generar el PDF
    let pdfData = doc.output('arraybuffer'); // Obtener los datos binarios del PDF
    // Convertir los datos binarios a Base64
    let base64PDF = await this.bufferToBase64(pdfData);
    // La variable base64PDF ahora contiene la representación en Base64 del PDF generado
    let resp:any = await this.uploadPDF(base64PDF);

    if (resp.status === 200) {
      window.open(resp.data.archivo);
    }else{
      this.notification.show('block', '', resp.data.error, '', 'error_sendcode', '');
    }

  }

  
  async getGrabarOP(){
    return new Promise((resolve, reject) => {
      this.collection = '/operacion/grabaroperacion';
      let verb = 'POST';
      let data = {
        contrato: this.contrato,
        operacion: "RET",//this.dataTransferencia.operacion === 'vender'? 'VTA':'CPA',
        //liq_forma_pago:this.dataOperation.cuenta,// opcion i 4 => liquidacion por indeval, fija, en venta], [para comprar probar con vacio o concentradora id 1.

        importe:this.dataOperation.amount,//this.dataTransferencia.efectivo,
        observaciones:"Movimiento desde de transferencia",
        liq_banco_cuenta:this.dataOperation.cuenta,//

        ip:this.coords.ip,
        latitud:this.coords.latitud,
        longitud:this.coords.longitud,
      };
      this.send(data, verb).subscribe(response => {
        resolve(response['data']);
      })
    });
  }
  
  cambiarformato(){
    let monto = parseFloat(this.dataOperation.monto);
    let saldo = Number(this.saldoEfectivo.replace(/[^0-9.]/g, ""));
    if (monto > saldo) {
      this.notification.show('block', '', 'No tiene suficientes fondos para transferir', '', 'error', '');
    }
    this.dataOperation.monto = this.formatearMonto(monto); 
    this.dataOperation.amount = monto; 
  }


  async getAdressComplete(){

    return new Promise((resolve, reject) => {
      this.collection = '/geolocalizacion';
      let verb = 'POST';
      let data = {
        latitud:this.coords.latitud,
        longitud:this.coords.longitud,
      };
      this.send(data, verb).subscribe(response => {
        if (response['data']) {
          resolve(response.data.data);
        }else{
          resolve({data:'sin resultado'})
        }
      })
    })
  }

  //LISTA DE ITEMS A IMPRIMIR 
  listItems(){

    let response;

    if (this.dataTransferencia.addefectivo === true) {
      response = [
        {
          text:"Operación",
          value:'AGREGAR A EFECTIVO DISPONIBLE'
        },
        {
          text:"Folio",
          value:'Agregar a efectivo disponible'
        },
        {
          text:"Fecha de solicitud",
          value:this.dataTransferencia.fecha_solicitud
        },
        {
          text:"Fecha de ejecución",
          value:this.dataTransferencia.fecha_ejecucion
        },
        {
          text:"Importe",
          value:'$ ' +this.dataTransferencia.importe
        }
      ];
    }else{

      response = [
        {
          text:"Operación",
          value:'TRANSFERENCIA'
        },
        {
          text:"Folio",
          value:this.dataTransferencia.folio_movimiento
        },
        {
          text:"Fecha de solicitud",
          value:this.dataTransferencia.fecha_solicitud
        },
        {
          text:"Fecha de ejecución",
          value:this.dataTransferencia.fecha_ejecucion
        },
        {
          text:"Destino de recursos",
          value:this.dataTransferencia.cuentadestino
        },
        {
          text:"Importe",
          value:'$ ' +this.dataTransferencia.importe
        }
      ];
    }

    return response;
  }

  uploadPDF(pdf){
    return new Promise((resolve, reject) => {
      this.collection = '/operacion/uploadpdf';
      let verb = 'POST';
      let data = {
        folio: this.dataTransferencia.folio_movimiento,
        pdf:pdf
      };
      this.send(data, verb).subscribe(response => {
        resolve(response);
      })
    });
  }

  // Función para convertir datos binarios a Base64
  async bufferToBase64(buffer) {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  


}
