<app-breadcum [data]="dataBreadcum"></app-breadcum>

<app-contracts
  [showDateRange]="true"
  [title]="'Movimientos'"
  (valueResponse)="getContactData($event)"></app-contracts>

  <div class="row me-4 ms-4">
    <div class="col-12 ps-0 pe-0">
      <div class="contt">
        <div class="d-flex flex-row mb-3">
          <div class="col-12 col-ms-12 col-md-6 chart-title d-flex align-items-center">
            <div>
              <span class="card-top-out-title table">Movimientos</span>
            </div>
          </div>
          <div class="col-12 col-ms-12 col-md-6 d-flex justify-content-end">
            <button
              *ngIf="!isDownloading"
              class="btn-alternative"
              (click)="printPDF()">
              <object class="me-2" data="/assets/icons/i-download-blue.svg" type=""></object>
              <span>Exportar</span>
            </button>
          </div>
        </div>
        
        <div id="movementsDiv" class="row d-lg-block mt-4 ml-4 mb-100">
          <div class="col-12">
            <div class="card ps-0 pe-0">
              <div class="scroll-table">
                <table id="movementsTable" class="table table-borderless table-movs">
                  <thead>
                    <tr>
                      <th>
                        <div class="d-flex flex-column">
                          <div>Fecha de&nbsp;</div>
                          <div>ingreso</div>
                        </div>
                      </th>
                      <th>Operación</th>
                      <th>Fondo</th>
                      <th>Serie</th>
                      <th>Títulos</th>
                      <th>
                        <div class="d-flex flex-column">
                          <div>Precio por</div>
                          <div>titulo</div>
                        </div>
                      </th>
                      <th>Importe</th>
                      <th>
                        <div class="d-flex flex-column">
                          <div>Importe a&nbsp;</div>
                          <div>liquidar</div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex flex-column">
                          <div>Fecha de&nbsp;</div>
                          <div>operación</div>
                        </div>
                      </th>
                      <th>
                        <div class="d-flex flex-column">
                          <div>Fecha de&nbsp;</div>
                          <div>liquidación</div>
                        </div>
                      </th>
                      <th>Detalle</th>
                      <th>Comprobante</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                  <tbody>
                    <tr *ngFor="let item of data">
                      <td class="text-center">{{ item.entryDate }}</td>
                      <td>{{ item.operation }}</td>
                      <td>{{ item.fund }}</td>
                      <td>{{ item.serie }}</td>
                      <td>
                        <div class="d-flex flex-row justify-content-end">
                          <div>
                            {{ item.titles.toString() | number : '1.0-0' }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex flex-row justify-content-end">
                          <div>$ &nbsp;</div>
                          <div>
                            {{ item.priceFormartZeros.toString() | number : '1.6-6' }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex flex-row justify-content-end">
                          <div>$ &nbsp;</div>
                          <div>
                            {{ item.amount.toString() | number : '1.2-2' }}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="d-flex flex-row justify-content-end">
                          <div>$ &nbsp;</div>
                          <div>
                            {{ item.amountToPay.toString() | number : '1.2-2' }}
                          </div>
                        </div>
                      </td>
                      <td class="text-center">{{ item.opDate }}</td>
                      <td class="text-center">{{ item.dueDate }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
        
              <div class="withoutData" *ngIf="data.length === 0">
                <span>No hay datos disponibles </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

