<ng-container *ngIf="showLoader">
  <div class="loading">
    <div class="d-flex flex-column align-items-center h-100">
      <div class="col-5">
        <img
          src="assets/images/logo-loader.png"
          alt="logo-compass"
          class="logo-loader w-100" />
      </div>

      <div class="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</ng-container>
