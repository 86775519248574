import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractsListComponent } from './contractList.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ContractsListComponent],
  exports: [ContractsListComponent],
  imports: [CommonModule, FormsModule],
})
export class ContractsListModule {}
