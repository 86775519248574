<form [formGroup]="myForm" class="mt-4">
  <div class="custom_row">
    <div class="col-6 d-flex flex-column">
      <label class="weight-500" for="nation">Nacionalidad</label>

      <div class="d-flex">
        <div class="form-check">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            value="Chilena"
            id="flexRadioDefault1"
            formControlName="nation" />
          <label class="form-check-label" for="Chilena"> Chilena </label>
        </div>
        <div class="form-check ms-4">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            value="Extranjero"
            id="flexRadioDefault2"
            formControlName="nation" />
          <label class="form-check-label" for="Extranjero">
            Extranjero residente
          </label>
        </div>
        <div class="form-check ms-4">
          <input
            class="form-check-input"
            type="radio"
            name="flexRadioDefault"
            value="no residente"
            id="flexRadioDefault3"
            formControlName="nation" />
          <label class="form-check-label" for="no residente">
            Extranjero no residente
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="custom_row">
    <div class="col-2 d-flex flex-column">
      <label class="weight-500" for="name">Nombres(s)</label>
      <input type="text" class="input_primary" formControlName="name" />
    </div>
    <div class="col-2 d-flex flex-column">
      <label class="weight-500" for="surname">Apellido Paterno</label>
      <input type="text" class="input_primary" formControlName="surname" />
      <div
        *ngIf="
          myForm.controls['surname'].invalid &&
          (myForm.controls['surname'].dirty ||
            myForm.controls['surname'].touched)
        ">
        <label class="red" for="error">Llenar el campo</label>
      </div>
    </div>
    <div class="col-2 d-flex flex-column">
      <label class="weight-500" for="second surname">Apellido Materno</label>
      <input
        type="text"
        class="input_primary"
        formControlName="second_surname" />
    </div>
    <div class="col-2 d-flex flex-column">
      <label class="weight-500" for="rut">RUT</label>
      <input type="text" class="input_primary" formControlName="rut" />
    </div>
  </div>

  <div class="custom_row">
    <div class="col-8 d-flex flex-column">
      <label class="weight-500" for="origin">Lugar de origen</label>
      <input type="text" class="input_primary" formControlName="origin" />
    </div>
  </div>

  <div class="custom_row">
    <div class="col-8 d-flex flex-column">
      <label class="weight-500" for="relasionship"
        >Relación con la persona jurídica declarante</label
      >
      <input type="text" class="input_primary" formControlName="relasionship" />
    </div>
  </div>

  <div class="custom_row">
    <div class="col-8 d-flex flex-column">
      <label class="weight-500 mb-3" for="JURAMENTO"
        >DECLARO BAJO JURAMENTO, QUE LA INFORMACIÓN PROPORCIONADA ES COMPLETA Y
        VERAZ</label
      >
      <div class="d-flex align-items-end">
        <label class="col-1" for="En">En</label>
        <div class="col-3">
          <input
            type="text"
            class="input_primary"
            placeholder="Lugar"
            formControlName="sign_place" />
        </div>
        <label class="col-2 text-center" for="En">,a</label>
        <div class="col-4">
          <input
            type="date"
            class="input_primary"
            formControlName="sign_date" />
        </div>
      </div>
    </div>
  </div>
</form>
