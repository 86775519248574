/**
 * @author       Eduardo Leonardo Torres Guevara <eduardo.tg@ix.agency>
 * @copyright    2023 IX Agency.
 * @lastmodifiedDate 14.02.2023 13:00
 */
import { Component, OnInit } from '@angular/core';
import { SessionService } from '../util/session/session.service';
import { BaseService } from '../base/base.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';


@Component({
  selector: 'app-portal-clientes',
  templateUrl: './portal-clientes.component.html',
  styleUrls: ['./portal-clientes.component.scss'],
})
export class PortalClientesComponent extends BaseService implements OnInit {


  public sendUser = new Subject();
  userObserbable = this.sendUser.asObservable();

  private currentPermitionsSubject: BehaviorSubject<string[]> =
    new BehaviorSubject(['']);
  public readonly currentPermitions: Observable<string[]> =
    this.currentPermitionsSubject.asObservable();


  public elements = ['Home'];
  public verifiPermisos:string;


  ngOnInit(): void {
    let email = this.session.getEmail();

    this.getAll('/contract?email=' + email).subscribe(response => {

      if (response.success !== false) {
        this.sendUser.next(response['data']);

        const bussines = response.data.companies.map(item => ({
          contracts: item.contracts,
          name: item.name,
          id: item.id + 1,
        }));
        localStorage.setItem('bussiness', bussines[0].id);
        localStorage.setItem('contract', bussines[0].contract);

        let arrayTransaccion:any = [...response.data.permission];
        arrayTransaccion = arrayTransaccion.slice(6,8);

        let arrayfirstMenu = [...response.data.permission];
        arrayfirstMenu = arrayfirstMenu.slice(0,5);


        for (const iterator of arrayfirstMenu) {
          if (iterator.allow === true) {
            this.elements.push(iterator.name);
          }
        }

        
        if (arrayTransaccion[0].allow === true && arrayTransaccion[1].allow === true) {
          this.elements.push(arrayTransaccion[0].name);
          this.elements.push('fondos');
        }

        this.elements.push('Documentos2');
        this.elements.push('Cerrar sesión');
        this.currentPermitionsSubject.next(this.elements);
        this.loaderTransparent.show(false);
      }
    });

    this.activateClosesesion();

  }

  getElements() {
    return this.elements;
  }
  
}
