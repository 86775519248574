import { Component, OnInit } from '@angular/core';
import { LoaderTransparentService } from './loaderTransparent.service';

@Component({
  selector: 'app-loader-transparent',
  templateUrl: './loaderTransparent.component.html',
  styleUrls: ['./loaderTransparent.component.scss'],
})
export class LoaderTransparentComponent implements OnInit {
  /**
   * @property {Boolean} Show or hide succes icon
   * @public
   */
  showLoader: boolean = false;

  constructor(private loader: LoaderTransparentService) {}

  ngOnInit() {
    this.loader.currentMessage.subscribe(response => {
      this.showLoader = response.showLoader;
    });
  }
}
