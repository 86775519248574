import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { BaseService } from 'src/app/base/base.service';

import { CustomValidators } from '../../common/validators/custom-validators';

@Component({
  selector: 'app-sing-up',
  templateUrl: './sing-up.component.html',
  styleUrls: ['./sing-up.component.scss'],
})
export class SingUpComponent extends BaseService implements OnInit {
  /**
   * @property {FormGroup} Form get only 2FA code
   * @public
   */
  public codeForm: FormGroup;
  /**
   * @property {FormGroup} Show or hide login view
   * @public
   */
  public showLogin: Boolean = true;
  /**
   * @property {String} Icon type/name
   * @public
   */
  public eyeIcon = 'visibility_off';
  /**
   * @property {String} Icon type/name
   * @public
   */
  public eyeIconConfirm = 'visibility_off';
  public userExist:boolean = false;

  ngOnInit(): void {
    this.myForm = this.createSignupForm();
    this.codeForm = this.fb.group({
      code: ['', Validators.required],
    });

    setTimeout(() => {
      this.myForm.patchValue({code:'',password:'',user:'', confirmPassword: ''});
    }, 800);
  }

  onSubmit(form: FormGroup) {
    this.collection = '/auth/signup';
    let verb = 'POST';
    this.loader.show(true);
    let data = {
      user: form.value.user.toLowerCase(),
      password: form.value.password,
    };
    //Send form data
    this.sendWithoutHeaders(data, verb).subscribe(response => {
      console.log(response);
      if (response.message == 'Success'){
        this.notification.show(
          'block',
          '',
          'Código enviado al correo.',
          '',
          'check',
          ''
        );
        this.showLogin = false;
      }
      
      if (response.message == 'El usuario no tiene contratos ligados') {
        this.notification.show(
          'block',
          '',
          response.message,
          '',
          'error',
          ''
        );
      }
      /* if(response.userexist == true){
        this.userExist = true;
        this.myForm.reset();
        //this.myForm.patchValue({password:'',user:'', confirmPassword: ''});
      } */
      this.loader.show(false);
    });
  }
  /**
   * @description Send 2FA code
   * @returns void
   */
  public sendCode(form: FormGroup) {
    this.collection = '/auth/signup/confirm';
    let verb = 'POST';
    this.loader.show(true);

    let data = {
      user: this.myForm.value.user.toLowerCase(),
      code: form.value.code.toString(),
      password: this.myForm.value.password,
    };
    this.sendWithoutHeaders(data, verb).subscribe(response => {
      if (response.message == 'Success')
        this.notification.show(
          'block',
          '',
          'Registro exitoso.',
          'Iniciar sesión',
          'check',
          '/login'
        );
      this.loader.show(false);
    });
  }
  /**
   * @description Send one more time 2FA code
   * @returns void
   */
  reSendCode(): void {
    this.collection = '/auth/code';
    let verb = 'POST';
    this.loader.show(true);
    let data = {
      user: this.myForm.value.user.toLowerCase(),
    };
    this.sendWithoutHeaders(data, verb).subscribe(response => {
      if (response.message == 'Success') {
        this.notification.show(
          'block',
          '',
          'Código enviado.',
          '',
          'check',
          '/login'
        );
      }

      this.loader.show(false);
    });
  }

  createSignupForm(): FormGroup {
    return this.fb.group(
      {
        user: [
          null,
          Validators.compose([Validators.email, Validators.required]),
        ],
        password: [
          null,
          Validators.compose([Validators.required, Validators.minLength(8)]),
        ],
        confirmPassword: [null, Validators.compose([Validators.required])],
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchingValidatior,
      }
    );
  }
  /**
   * @description Can change input typefrom password to text
   * @returns void
   */
  public showPass() {
    this.eyeIcon == 'visibility_off'
      ? (this.eyeIcon = 'visibility')
      : (this.eyeIcon = 'visibility_off');

    let inputPass = document.getElementById('password')!;

    if (inputPass.getAttribute('type') === 'password') {
      inputPass.setAttribute('type', 'text');
    } else {
      inputPass.setAttribute('type', 'password');
    }
  }
  /**
   * @description Can change input typefrom passwordConfirm to text
   * @returns void
   */
  public showPassConfirm() {
    this.eyeIconConfirm == 'visibility_off'
      ? (this.eyeIconConfirm = 'visibility')
      : (this.eyeIconConfirm = 'visibility_off');

    let inputPass = document.getElementById('passwordConfirm')!;

    if (inputPass.getAttribute('type') === 'password') {
      inputPass.setAttribute('type', 'text');
    } else {
      inputPass.setAttribute('type', 'password');
    }
  }
}
