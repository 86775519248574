import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { BaseService } from 'src/app/base/base.service';

@Component({
  selector: 'app-login-two-factor',
  templateUrl: './login-two-factor.component.html',
  styleUrls: ['./login-two-factor.component.scss'],
})
export class LoginTwoFactorComponent extends BaseService implements OnInit {
  /**
   * @property {} Save session user from signin service
   * @private
   */
  private sessionUser: any;

  subscription: Subscription;

  public sendUser = new Subject();
  userObserbable = this.sendUser.asObservable();

  ngOnInit(): void {

    this.loader.show(true);
    let authSubscription = this.auth.user$;

    this.myForm = this.fb.group({
      code: ['', [Validators.required, Validators.minLength(4)]],
    });

    if (this.session.inTwoFaSession()) {
      this.router.navigate(['/dashboard/home']);
     // this.loader.show(false);
      return;
    }

    this.subscription = authSubscription.subscribe(response => {
     
      if (response !== null) {
        // console.log(1);
        this.sessionUser = response;
      //  localStorage.setItem('email', this.sessionUser.email);
        this.onSubmitLogin();
      } else {
        // console.log(2);
        this.router.navigate(['/login']);
      }
      //this.subscription.unsubscribe();
   //   this.loader.show(false);
    });

    /* setTimeout(async () => {
      await this.getCheckTime();
      console.log(localStorage.getItem("timeout"))
      
    }, 5000); */
  }

  /**
   * @description Request 2FA code with email and pass
   * @returns void
   */
  onSubmitLogin() {
    this.collection = '/auth/signin';
    let verb = 'POST';
    this.loader.show(true);
    let data = {
      user: this.sessionUser.email,
    };
    this.sendWithoutHeaders(data, verb).subscribe(response => {
      if (response.message === 'Success') {
        this.notification.show(
          'block',
          '',
          'Código enviado al correo.',
          '',
          'check',
          ''
        );
      }else if (response.data.replace(/[\/"]/g, "") == "Correo no habilitado, por favor contactarse con su asesor.") {
        this.notification.show(
          'block',
          '',
          response.data.replace(/[\/"]/g, ""),
          '',
          'error',
          ''
        );
      } else {
        this.notification.show(
          'block',
          '',
          'Error interno. Intente más tarde.',
          '',
          'error',
          ''
        );
      }
    });
    this.loader.show(false);
  }

  /**
   * @description Signin service
   * @returns void
   */
  sendCode(form: FormGroup): void {
    this.collection = '/auth/signin/mfa';
    let verb = 'POST';
    this.loader.show(true);

    let data = {
      username: this.sessionUser.email,
      answer: form.value.code.toString(),
      session: this.sessionUser.sub,
    };
    this.sendWithoutHeaders(data, verb).subscribe(response => {
      if (response.message == 'Success') {
        localStorage.setItem('email', this.sessionUser.email);
        this.session.create(response.data);
      } else {
        this.notification.show(
          'block',
          '',
          'Código incorrecto.',
          '',
          'error',
          ''
        );
      }
      this.loader.show(false);
    });
  }

  /**
   * @description Send one more time 2FA code
   * @returns void
   */
  reSendCode(): void {
    this.collection = '/auth/signin';
    let verb = 'POST';
    this.loader.show(true);
    let data = {
      user: this.myForm.value.user,
      password: this.myForm.value.password,
    };
    this.sendWithoutHeaders(data, verb).subscribe(response => {
      if (response.message == 'Success') {
        this.sessionUser = response.data.session;
        this.notification.show(
          'block',
          '',
          'Código enviado.',
          '',
          'check',
          '/login'
        );
      }

      this.loader.show(false);
    });
  }

  /**
   * @description route to login
   * @returns void
   */
  backToLogin(): void {
    this.session.destroy();
  }
}
