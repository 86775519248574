import { Component, OnInit } from '@angular/core';
import { BaseService } from '../../base/base.service';

@Component({
  selector: 'app-manual-usuarios',
  templateUrl: './manual-usuarios.component.html',
  styleUrls: ['./manual-usuarios.component.scss'],
})
export class ManualUsuariosComponent extends BaseService implements OnInit {
  ngOnInit(): void {
    this.sendDataToBreadcum(['Home', 'Manual de Usuarios']);
  }

  /**
   *
   * @param breadcum
   */
  private sendDataToBreadcum(breadcum: string[]) {
    this.titleContainerService.show(
      true,
      breadcum,
      'Manual de Usuarios Reportes de Inversión'
    );
  }
}
