<div id="myNotification" class="notification">
  <div class="h-100 w-100 d-flex" (click)="change()">
    <div [ngClass]="iconType == 'check_sendcode' || iconType == 'error_sendcode' ? 'col-xl-4 top-content':'col-xl-3'" class="notification-content col-md-4 col-10">
      <div class="d-flex justify-content-end" *ngIf="route">
        <a routerLink="/{{ route }}" routerLinkActive="active">
          <span class="material-symbols-outlined pointer"> close </span>
        </a>
      </div>
      <ng-container *ngIf="iconType === 'check' || iconType === 'error'; else othertemplate">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <span
            class="material-symbols-outlined f-30 green mb-2"
            *ngIf="iconType === 'check'">
            check_circle
          </span>
          <span
            class="material-symbols-outlined f-30 yellow mb-2"
            *ngIf="iconType === 'error'">
            error
          </span>
          <div class="text-center">
            <span class="title-notification mb-3">{{ title }}</span>
          </div>
          <button
            class="btn btn-primary w-100"
            (click)="redirectTo()"
            *ngIf="textButton">
            {{ textButton }}
          </button>
        </div>
      </ng-container>
      <ng-template #othertemplate>
        <div class="d-flex flex-column justify-content-center align-items-center">

          <span class="material-symbols-outlined f-30 green mb-2" *ngIf="iconType === 'check_sendcode'">
            check_circle
          </span>

          <span class="material-symbols-outlined f-30 yellow mb-2" *ngIf="iconType === 'error_sendcode'">
            error
          </span>

          <ng-container *ngIf="title === 'procesando';else other">
            <span class="title-notificacion-code mb-1">PROCESANDO OPERACIÓN</span>
            <div class="text-justify">
              <span class="text-notificacion-code mb-3" >Una vez que se apruebe la podrás ver reflejada en la sección de <a *ngIf="iconType === 'check_sendcode'" routerLink="/dashboard" routerLinkActive="active">Órdenes del día</a></span>
            </div>
          </ng-container>
          <ng-template #other>
            <span class="title-notificacion-code mb-1">{{subtitle}}</span>
  
            <div class="text-justify">
              <span class="text-notificacion-code mb-3" >{{title}} <a *ngIf="iconType === 'check_sendcode'" routerLink="/dashboard" routerLinkActive="active">Movimientos</a></span>
            </div>
          </ng-template>

        </div>
      </ng-template>
    </div>
  </div>
</div>
