/**
 * @author       Eduardo Leonardo Torres Guevara <eduardo.tg@ix.agency>
 * @copyright    2023 IX Agency.
 * @lastmodifiedDate 09.01.2023 13:17
 */
import { formatDate } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { BaseService } from 'src/app/base/base.service';
declare var bootstrap: any;

@Component({
  selector: 'app-descargas',
  templateUrl: './descargas.component.html',
  styleUrls: ['./descargas.component.scss'],
})
export class DescargasPortalComponent extends BaseService implements OnInit, AfterViewInit {
  companies: any = [];
  records: any = [];
  recordsByYear: any = [];
  resumen: any = [];

  contracts: string[] = [];

  selectedReport: string = '';
  seletedReportName: string = '';
  dateReport: string = '';

  selectedContract: string = '';
  showExample: Boolean = false;

  highlightMontly = false;
  highlightAnnual = false;

  changeBusiness = false;

  pdfPath = '';
  zipPath = '';

  showMessageToDowload = false;

  selectedDocument:string = '';
  documentType: any = [];
  changeTypes = false;
  selectedType:string = '';
  selectedItem: boolean = false;
  tooltip:any;

  ngOnInit(): void {
    localStorage.setItem('itemSelected','Documentos2');
    this.dataBreadcum = [{
      breadcum: ['Home', 'Portal de clientes','Descargas'],
      title: 'Descargas',
      subTitle : '',
      icon:false,
      path:''
     }]
    this.loader.show(true);
    let email = this.session.getEmail();
    this.getAll('/contract?email=' + email).subscribe(response => {
      this.companies = response['data'] ? response.data.companies : [];
      this.loader.show(false);
    });

   
  }
  ngAfterViewInit(): void {
    // Initialize Bootstrap tooltips
    var exampleTriggerEl = document.getElementById('tooltip')
    this.tooltip = bootstrap.Tooltip.getOrCreateInstance(exampleTriggerEl) // Returns a Bootstrap tooltip instance
  }



  public onChangeBusiness(event): void {
    let index = event.target.value;
    
    this.contracts = [];
    this.contracts = this.companies[index].contracts;
    this.seletedReportName = this.companies[index].name;
    this.selectedContract = '';
    this.showExample = false;
    this.highlightMontly = false;
    this.highlightAnnual = false;
    this.changeBusiness = true;
  }

  public onChangeContract(event) {
    if (event.target.value === 'Seleccione un contrato') {
      return false;
    }
    this.loaderTransparent.show(true);
    this.selectedContract = event.target.value;
    this.selectedDocument = '';
    this.AsingSelect();
    this.showExample = false;
    this.highlightAnnual = false;
    this.highlightMontly = false;
    this.changeTypes = true;

    this.getAll(
      '/contract/' + this.selectedContract + '/file?type=EDOCTA'
    ).subscribe(response => {
      this.resumen = response.data.estados_cuenta.map(item => ({
        ...item,
        fecha: this.formatDate(item.fecha),
      }));
    });

    this.getAll(
      '/contract/' + this.selectedContract + '/file?type=CONSTANCIA'
    ).subscribe(response => {
      let records = response.data.constacias_fiscales.map(
        item => item.ejercicio
      );

      this.recordsByYear = [...new Set(records)];
      this.records = response.data.constacias_fiscales;
    });

    setTimeout(() => {
      this.loaderTransparent.show(false);
    }, 1500);
    return true;
  }

  public AsingSelect():void{
    this.documentType = [
      {
        text: 'Estados de cuenta',
        value: 'estados',
      },
      {
        text: 'Constancias Fiscales',
        value: 'constancias'
      }
    ];

  }

  public onChangeDocumentType(event):void {
    console.log(event.target.value);
    this.selectedType = event.target.value;
  }

  public onChangeMonthlyReport(event): void {
    this.loaderTransparent.show(true);
    let report = this.resumen[event.target.value];

    if (event.target.value == 'false') {
      this.loaderTransparent.show(false);
      return;
    }
    this.selectedReport = this.seletedReportName + '- Estado de cuenta';
    this.dateReport = report.fecha;
    this.getAll(
      '/contract/' +
        this.selectedContract +
        '/file?action=download&type=EDOCTA&file=' +
        report.edo_cuenta_id
    ).subscribe(response => {
      this.pdfPath = response.data.signedUrl
        .substring(1)
        .slice(0, -1)
        .toString();
      this.showExample = true;
      this.highlightMontly = true;
      this.highlightAnnual = false;
      this.selectedItem = true;
      this.loaderTransparent.show(false);
    });
  }

  public onChangeAnnualReport(event): void {
    this.loaderTransparent.show(true);
    let report = this.recordsByYear[event.target.value];
    if (event.target.value == 'false') {
      this.loaderTransparent.show(false);
      return;
    }

    this.dateReport = report;
    this.selectedReport = 'Constancias fiscales';
    this.selectedItem = true;
    this.showExample = true;
    this.highlightAnnual = true;
    this.highlightMontly = false;
    this.loaderTransparent.show(false);
  }

  public dowlandZip() {
    let filterRecords = this.records.filter(
      item => item.ejercicio == this.dateReport
    );

    for (const iterator of filterRecords) {
      this.getAll(
        '/contract/' +
          this.selectedContract +
          '/file?action=download&type=CONSTANCIA&file=' +
          iterator.constancia_id
      ).subscribe(response => {
        let zipPath = response.data.signedUrl
          .substring(1)
          .slice(0, -1)
          .toString();
        window.open(zipPath);
      });
    }
  }

  public showMessage() {
    this.showMessageToDowload = !this.showMessageToDowload;
  }

  private formatDate(date) {
    
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Setiembre", "Octubre", "Noviembre", "Diciembre"];
    const partes = date.split("-");
    const anio = parseInt(partes[0], 10);
    const mes = parseInt(partes[1], 10) - 1;
    // Crear una nueva fecha
    const fecha = new Date(anio, mes);
  
    return meses[fecha.getMonth()] + ' ' + fecha.getFullYear();
  }
}
