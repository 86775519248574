/**
 * @autor Carlos Santiago <carlos.sp@ix.agency>
 */
import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  DownloadInterface,
  FieldsDownloadInterface,
} from 'src/app/common/interfaces/DownloadResponseInterface';

import { BaseService } from '../../base/base.service';
import { IResponseFile } from 'src/app/common/interfaces/downloadDinamicInterface';

@Component({
  selector: 'app-descargas',
  templateUrl: './descargas.component.html',
  styleUrls: ['./descargas.component.scss'],
})
export class DescargasComponent extends BaseService implements OnInit {
  optionDownloadSelected: any = {};

  // variable para las opciones del select file
  fileOptions: DownloadInterface[];

  public fieldSelected: FieldsDownloadInterface[] = [];
  //
  selectFileValue: number = 0;

  // variable para validar las descargas
  download = true;

  showDownloadSection = false;

  public fileResponse: IResponseFile;

  ngOnInit() {
    localStorage.setItem('itemSelected','Documentos');
    this.loaderTransparent.show(true);
    this.sendDataToBreadcum(['Home', 'Documentos']);
    this.getData();
    
    
  }

  /**
   * Its used to handling dates in fomart dd/mm/yyyy
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   * @param {any} dateString - Date
   */
  formatDate(dateString) {
    var dateParts = dateString.split('/');
    var date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return date;
  }

  /**
   * onSelectDownloadFile
   * Select download file type
   * @property {item} download type selected. example : Facturas o tributarios
   */
  public onSelectDownloadFile(item) {
    let id = item.target.value;
    if (id === 'non-selected') return;
    this.optionDownloadSelected = this.fileOptions[id];
    this.fieldSelected = this.optionDownloadSelected?.campos || [];
    this.showDownloadSection = false;
    this.createForm();
    this.sendDataToBreadcum([
      'Home',
      'Documentos',
      this.optionDownloadSelected.nombre,
    ]);
  }

  /**
   * onSubmitForm
   */
  onSubmitForm(form) {
    this.loaderTransparent.show(true);
    let fields: any = [];

    let headers = Object.keys(form.value);
    let values = Object.values(form.value);

    for (const [index] of headers.entries()) {
      fields.push({ id_campo: headers[index], valor: values[index] });
    }

    let data = {
      id_descarga: this.optionDownloadSelected.id,
      campos: fields,
    };
    this.collection = '/descargas/archivo';
    let verb = 'POST';
    this.send(data, verb).subscribe((response:any) => {
      console.log(response);
      if (response.status == "-6") {
        this.notification.show(
          'block',
          '',
          response.error,
          '',
          'error',
          ''
        );
        this.loaderTransparent.show(false);
        return;
      }
      this.fileResponse = response.data;//NUEVO DESCARGAS
      //this.fileResponse = response.data[0];//ANTIGUO DESCARGAS
      this.showDownloadSection = true;
      this.notification.show(
        'block',
        '',
        'Archivos listos para descargarse',
        '',
        'check',
        ''
      );
      this.loaderTransparent.show(false);
    });
  }

  public validateAllIsFill() {
    if (this.myForm.valid === true) {
      // this.showDownloadSection = true;
    }
  }

  /**
   * showFile
   */
  public showFile() {
    //window.open(this.url);
    this.downloadFile();
  }

  /* Private Zone */
  private createForm() {
    let fields = {};
    for (const iterator of this.fieldSelected) {
      let name = iterator['nombre'];
      fields[name] = ['', Validators.required];
    }
    this.myForm = this.fb.group(fields);
  }

  private getData() {
    let email = this.session.getEmail();

    this.collection = '/descargas?cliente=' + email;
    let verb = 'POST';
    this.send({}, verb).subscribe({
      next: (response:any) => {
        this.fileOptions = response.data;
      },
      complete:()=> {
        this.loaderTransparent.show(false);
      }
    });
  }

  private sendDataToBreadcum(breadcum: string[]) {
    this.titleContainerService.show(
      true,
      breadcum,
      'Documentos',
      'En esta sección podrás descargar distintos tipos de documentos <br> relacionados a tus inversiones domiciliadas en Chile. <br>Selecciona el tipo de archivo que deseas descargar del listado.'
    );
  }

  /**
   * Its used to transform base 64 to bytes
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   * @param {String} base64String - Base 64
   */
  public base64ToArrayBuffer(base64String: string) {
    let binaryString = window.atob(base64String);
    let binaryLength = binaryString.length;
    let bytes = new Uint8Array(binaryLength);
    for (let i = 0; i < binaryLength; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
  /**
   * Its used to identify type of file and download
   * @autor Carlos Santiago <carlos.sp@ix.agency>
   * @param {string} filename - Name of file
   * @param {string} documentType - Type of file
   * @param {string} bas64String - Base 64
   */
  public downloadDocument(
    filename: string,
    documentType: string,
    //base64String: string,//ANTIGUI DESCARGAS
    url: string//NUEVO DESCARGAS
  ): void {
    let documentTypeAvailable = new Map();
    documentTypeAvailable.set(
      'excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    );
    documentTypeAvailable.set(
      'word',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );
    documentTypeAvailable.set('pdf', 'application/pdf');
    documentTypeAvailable.set('zip', 'application/zip');
    //ANTIGUO DESCARGAS
   /*  let bytes = this.base64ToArrayBuffer(base64String);
    let blob = new Blob([bytes], {
      type: documentTypeAvailable.get(documentType),
    });
    var data = window.URL.createObjectURL(blob); */
    //ANTIGUO DESCARGAS
    /* var link = document.createElement('a');
    document.body.appendChild(link);
    link.href = data;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove(); */
    //NUEVO DESCARGAS
   var link = document.createElement('a');
    document.body.appendChild(link);
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }

  downloadFile() {
    console.log(this.fileResponse);
    this.downloadDocument(
      this.fileResponse.archivo,
      this.fileResponse.extension,
      //this.fileResponse.data,//ANTIGUO DESCARGAS
      this.fileResponse.url//NUEVO DESCARGAS
    );
  }
}
